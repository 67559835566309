import axios from "axios";
 
var baseURL =process.env.REACT_APP_API_URL;
 
const Api = axios.create({
    baseURL: baseURL,
    headers: { authorization: sessionStorage.getItem('accessToken')}
  });



  Api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
     
      if (error?.response?.status === 401) {
        // toast.error('Session Expired');
        setTimeout(() => {
          sessionStorage.clear();
          window.location.reload(false);
          window.location.href = '/';
        }, 1000);
      }
  
      if (error?.response?.status === 404) {
      }
    }
  );
  
  export default Api;
