import React from "react";
import WheelComponent from "react-wheel-of-prizes";
import "react-wheel-of-prizes/dist/index";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addPrizeDraw } from "../../Redux/Action/AdminPannel";
import PrizeDraw from "../Prize_draw/PrizeDraw";
const Spiner = ({ subscribers, qlist, subDetail }) => {
  //  console.log(qlist,"list");
  const [win, setWinner] = useState();

  const [filterData, setFilterData] = useState(null);
  const dispatch = useDispatch();

  // console.log(subDetail, "subDetail");
  // useEffect(()=>{
  //   let data=
  // })

  // useEffect(() => {
  //   if (subscribers) {
  //     let data = subscribers?.subscribes?.user_detail
  //       ?.map((d) => d.gamer_name)
  //       .filter((item) => item !== undefined);
  //     setFilterData(data);
  //     console.log(subscribers.subscribes.user_detail);
  //      console.log(data);
  //   }
  // }, []);
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#34A24F",
    "#F9AA1F",
    "#EC3F3F",
    "#FF9000",
  ];
  const onFinished = (winner) => {
    const idResult = subDetail?.subscribes?.user_detail?.filter(
      (item, i) => item?.gamer_name == winner
    );
    const questId=subDetail?._id
    // console.log(questId,"quest id");
    // console.log(idResult, "winneriiiii");

    const winId=idResult?.map((item)=>item?._id)
    setWinner(winner);
    dispatch(addPrizeDraw({quest_id:questId, user_id:winId[0]}));
  }; 

  // console.log(subscribers, "subscribers");
  return (
    <div className="spinner">
      {subscribers && (
        <WheelComponent
          // title={title}
          segments={subscribers}
          segColors={segColors}
          winningSegment={win}
          onFinished={(findId) => onFinished(findId)}
          primaryColor="white"
          contrastColor="black"
          buttonText="SPIN"
          isOnlyOnce={false}
          size={290}
          upDuration={100}
          downDuration={200}
          fontFamily="Arial"
        />
      )}
    </div>
  );
};

export default Spiner;
