import React, { useState } from "react";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
const UserVerificationModal = ({
  viewVal,
  setViewVal,
  setUserObject,
  userObject,
  handleReply,
}) => {
  const handleClose = () => {
    setViewVal(false);
    setUserObject(null);
  };
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const [viewModalImg, setViewModalImg] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);

  console.log(userObject, "list of object");

  let images = [
    IMG_URL + "user_docs/" + userObject?.front_image,
    IMG_URL + "user_docs/" + userObject?.back_image,
    IMG_URL + "user_docs/" + userObject?.selfie
  ];

  const onClickHandlerClose = () => {
    setViewModalImg(false);
  };

  const onClickOnMovePrevRequest = () => {
    setphotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const onClickOnMoveNextRequest = () => {
    setphotoIndex((photoIndex + 1) % images.length);
  };

  const onImageClickk = (index) => {
    setphotoIndex(index);
    setViewModalImg(true);
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button> */}

      {viewModalImg == false && (
        <Modal show={viewVal} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{userObject?.first_name}</Modal.Title>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <form action="">
                <div className="detail_page_content">
                  <div className="course_info">
                    <div className="d-flex mb-2 align-items-center">
                      <div className="pe-2  main_profile_img">
                        {userObject?.main_image ? (
                          <img
                            src={
                              IMG_URL +
                              "user_profile_image/" +
                              userObject?.main_image
                            }
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        ) : (
                          <img
                            src={require("../assets/def_user.png")}
                            alt="avatar"
                            className="img-fluid mx-auto rounded-circle"
                          />
                        )}
                      </div>
                      <div>
                        <div className="d-flex justify-content-center">
                          <h4>
                            {userObject?.first_name.concat(
                              " ",
                              userObject?.sur_name
                            )}
                            ,{" "}
                            <span>
                              {new Date().getFullYear() -
                                new Date(
                                  userObject?.date_of_birth
                                ).getFullYear()}
                            </span>
                            <div className="gamer-name">
                              {" "}
                              <label for="">Gamers Name :</label>
                              {userObject?.gamer_name}
                            </div>
                          </h4>
                        </div>
                        <div className="d-flex justify-content-center1 country_details">
                          <p className="mb-0">
                            {/* <img
                            src={""}
                            className="flag-width img-fluid"
                            alt="flag"
                          /> */}
                            <span>{userObject?.country}</span>
                          </p>
                          <p className="mb-0 ms-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="feather feather-user"
                            >
                              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                              <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span className="">
                              {userObject?.gender == 1 ? "Male" : "Female"}
                            </span>
                           
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 about-section">
                    <h5>About me </h5>
                    <p>{userObject?.about_me}</p>
                  </div>

                  <div className="row mb-3 mt-4 images_card">
                    <div className="col-md-4  p-3">
                      <h5>Back view</h5>
                      <a
                        onClick={() => onImageClickk(0)}
                        // href="assets/img/front.png"
                        className="defaultGlightbox glightbox-content"
                      >
                        <img
                          src={IMG_URL + "user_docs/" + userObject?.front_image}
                          alt="frontImage"
                          className="img-fluid  modal_img_h"
                        />
                      </a>
                    </div>
                    <div className="col-md-4  p-3">
                      <h5>Front view</h5>
                      <a
                        // href="assets/img/back.png"
                        className="defaultGlightbox glightbox-content"
                        onClick={() => onImageClickk(1)}
                      >
                        <img
                          src={IMG_URL + "user_docs/" + userObject?.back_image}
                          alt="backImage"
                          className="img-fluid modal_img_h"
                        />
                      </a>
                    </div>
                    <div className="col-md-4  p-3">
                      <h5>Selfie</h5>
                      <a
                        onClick={() => onImageClickk(2)}
                        // href="assets/img/selfie-men.jpg"
                        className="defaultGlightbox glightbox-content"
                      >
                        <img
                          src={IMG_URL + "user_docs/" + userObject?.selfie}
                          alt="selfie"
                          className="img-fluid mx-auto bdr_radius modal_img_h"
                        />

                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="btn btn-light-dark" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="btn drk_btn"
              onClick={() => handleReply(userObject?.email)}
            >
              Reply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {viewModalImg == true && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={onClickHandlerClose}
          onMovePrevRequest={onClickOnMovePrevRequest}
          onMoveNextRequest={onClickOnMoveNextRequest}
        />
      )}
    </>
  );
};

export default UserVerificationModal;
