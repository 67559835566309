import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Userlist,
  Userstatusupdate,
  Writeemail,
} from "../../Redux/Action/LoginAction";
import Master from "../Include/Master";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CSVLink, CSVDownload } from "react-csv";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { countries } from "./countries";
import {
  GetGamelistActionV2,
  getInterests,
  handleSubscription,
  liveGoStatus,
} from "../../Redux/Action/AdminPannel";
export default function UserManagement() {
  const countryArray = [
    "USA",
    "UK",
    "India",
    "Russia",
    "Ukraine",
    "Dubai",
    "Canada",
  ];
  const genderArray = [{ Male: "1" }, { Female: "0" }, { Others: "2" }];
  const gameGenreArray = [
    "Action Games",
    "RPG",
    "Strategy Games",
    "Simulation games",
    "Sport games",
    "Survival & horror games",
    "MMORPG",
    "Shooter games",
    "Mobile games",
    "Card games",
  ];
  const interestsArray = [
    "Music",
    "Cooking",
    "Swimming",
    "Shopping",
    "Speeches",
    "Art & Crafts",
    "Reading",
    "Drinking",
    "Photography",
    "Fitness",
  ];

  const subscription = [{ Subscribed: "1" }, { "Not-Subscribed": "0" }];

  const [show, setShow] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    country: [],
    gender: [],
    gameGenre: [],
    interests: [],
    subscription: [],
    game: [],
  });
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [value, setValue] = useState("");
  const [email, setemail] = useState("");
  const [subject, setSubject] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [userList, setUserList] = useState();
  const [selectVal, setSelectedVal] = useState();
  const [gameselectVal, setgameSelectedVal] = useState();
  const [csvData, setCsvData] = useState();
  const [country, setCountry] = useState([]);
  const tempList = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userlist = useSelector((state) => state.Loginreduce.user_list);

  var values = {};
  useEffect(() => {
    dispatch(Userlist(search));
    dispatch(getInterests());
  }, [selectedFilter]);

  useEffect(() => {
    if (userlist) {
      setUserList(userlist);
      tempList.current = userlist;
    }
  }, [userlist]);

  useEffect(() => {
    dispatch(GetGamelistActionV2());
  }, []);

  const gamelist = useSelector((state) => state.AdminReducer.game_list);
  console.log(gamelist, "games");

  useEffect(() => {
    if (tempList.current?.length > 0) {
      setCsvData([
        [
          "S.No",
          "Name",
          "Surname",
          "Gamer Name",
          "Email",
          "Phone No.",
          "Verification",
          "Country",
          "Gender",
          "Status",
        ],
        ...tempList.current?.map((user, i) => {
          const phoneNumber = user.country_code + " " + user.phone_number;
          return [
            i + 1,
            user?.first_name,
            user?.sur_name,
            user?.gamer_name,
            user?.email,
            phoneNumber,
            user.admin_approve == 1 ? "Verified" : "Unverified",
            user.country,
            user.gender == 1 ? "Male" : user.gender == 2 ? "Female" : "Others",
            user.status == 0
              ? "Active"
              : user.status == 2
              ? "Temporarily banned"
              : "Banned",
          ];
        }),
      ]);
    }
  }, [tempList.current]);

  const handleClose = () => setShow(false);
  const handleShow = (email) => {
    setShow(true);
    setemail(email);
  };
  const filterClose = () => {
    setFilterShow(false);
  };
  const submitform = () => {
    if (value == "") {
      setShow(true);
    } else {
      values.subject = subject;
      values.content = value;
      values.email = email;
      dispatch(Writeemail(values)).then(function () {
        setShow(false);
        setValue("");
      });
    }
  };

  const handleCountrySelect = (e) => {
    console.log(e,"country")
    setSelectedVal(e);
    const countries = e.map((obj) => {
      return obj.label;
    });
    setSelectedFilter((prev) => {
      return {
        ...prev,
        country: countries,
      };
    });
  };

  const handleGameSelect = (e) => {
    console.log(e,"game")
    setgameSelectedVal(e);
    const gamelist = e.map((obj) => {
      return obj.value;
    });
    setSelectedFilter((prev) => {
      return {
        ...prev,
        game: gamelist,
      };
    });
  };
  console.log(selectedFilter);

  // const handleCountry=(e)=>{
  //   setCountry((old)=>[...old,e.target.value])

  // }
  // console.log(country,"country")

  const handleFilterChange = async (key, value) => {
    setSelectedFilter((prev) => {
      var newObject;

      if (prev[key].includes(value)) {
        const arr = prev[key].filter((val) => val !== value);
        newObject = {
          ...prev,
          [key]: [...arr],
        };
      } else {
        newObject = {
          ...prev,
          [key]: [...prev[key], value],
        };
      }
      return newObject;
    });
  };

  const applyFilter = () => {
    dispatch(Userlist(selectedFilter));
    setFilterShow(false);

    // tempList.current = userlist;
    // setFilterShow(false);
    // if (selectedFilter.country.length > 0) {
    //   let countryArr = tempList.current.filter((user) =>
    //     selectedFilter.country.includes(
    //       user.country?.split(" ")[1]?.toLowerCase()
    //     )
    //   );
    //   tempList.current = countryArr;
    // }

    // if (selectedFilter.gender.length > 0) {
    //   let genderArr = tempList.current.filter((user) =>
    //     selectedFilter.gender.includes(user.gender?.toString())
    //   );
    //   tempList.current = genderArr;
    // }

    // if (selectedFilter.gameGenre.length > 0) {
    //   const gameGenreArray = tempList.current.filter((user) =>
    //     selectedFilter.gameGenre.some((gameGen) =>
    //       user.game_geners
    //         .map((game) => game.toLowerCase())
    //         .includes(gameGen.toLowerCase())
    //     )
    //   );
    //   tempList.current = gameGenreArray;
    // }

    // if (selectedFilter.interests.length > 0) {
    //   const interests = tempList.current.filter((user) =>
    //     selectedFilter.interests.some((interest) =>
    //       user.interest
    //         .map((int) => int.toLowerCase())
    //         .includes(interest.toLowerCase())
    //     )
    //   );
    //   tempList.current = interests;
    // }

    // const newArr = [
    //   ...countryArr,
    //   ...genderArr,
    //   ...gameGenreArray,
    //   ...interests,
    // ];

    // const uniqueArr = newArr.filter(
    //   (value, index, self) =>
    //     index === self.findIndex((el) => el._id === value._id)
    // );

    // setUserList(uniqueArr);
  };

  const handleUserDetailPage = (id) => {
    navigate(`/usermanagement/user-detail/${id}`);
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % userlist?.length);
  };

  useEffect(() => {
    if (page && tempList.current?.length > 0) {
      setPageCount(Math.ceil(tempList.current?.length / page));
    }
  }, [tempList?.current, page]);

  console.log(gamelist, "game");

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>User Management</a>
              </li>
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end me-4">
                {csvData && (
                  <CSVLink data={csvData}>
                    <button className="" id="btn-add-contact">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-download"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                      </svg>
                    </button>
                  </CSVLink>
                )}
              </div>
              <div className="btn-group  mb-2 " role="group ml-2">
                {/* <button
                  id="btndefault"
                  type="button"
                  className="btn btn-outline-dark dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Filter{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-download"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                </button> */}
              </div>
              <button
                onClick={() => setFilterShow(true)}
                id="btndefault"
                type="button"
                className="btn btn-outline-dark dropdown-toggle"
                data-bs-toggle="dropdown"
                // aria-haspopup="true"
                aria-expanded="false"
              >
                Filter
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </button>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg> */}

              <Modal
                show={filterShow}
                onHide={filterClose}
                className="user_filter_select"
              >
                <Modal.Header>
                  <Modal.Title>Filter</Modal.Title>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={filterClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-x"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </button>
                </Modal.Header>
                <Modal.Body>
                  {/* <div className="btn-group  mb-2 " role="group ml-2">
                  </div> */}
                  {/* <div
                    className="dropdown-menu filter_menu"
                    aria-labelledby="btndefault"
                  >
                  </div> */}

                  <div className="modal_inner_content">
                    <a className="dropdown-item mb-4 select_option_filters">
                      <h5>Country</h5>
                      <ul className="list-none p-0 filter_options d-flex mb-0">
                        <Select
                          isMulti
                          onChange={handleCountrySelect}
                          options={countries}
                          value={selectVal}
                        />

                        {/* {countryArray?.map((country, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() =>
                              handleFilterChange("country", country)main
                            }
                            className={
                              selectedFilter.country.includes(country)
                                ? "mb-2 me-4 active"
                                : "mb-2 me-4"
                            }
                          >
                            {country}
                          </li>
                        );
                      })} */}
                      </ul>
                    </a>
                    <a className="dropdown-item mb-4">
                      <h5>Gender</h5>
                      <ul className="list-none p-0 filter_options  d-flex mb-0">
                        {genderArray?.map((gender, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              handleFilterChange(
                                "gender",
                                gender[Object.keys(gender)[0]]
                              )
                            }
                            className={
                              selectedFilter.gender.includes(
                                gender[Object.keys(gender)[0]]
                              )
                                ? "mb-2 me-2 active"
                                : "mb-2 me-2"
                            }
                          >
                            {Object.keys(gender)[0]}
                          </li>
                        ))}
                      </ul>
                    </a>
                    <a className="dropdown-item mb-4">
                      <i className="flaticon-bell-fill-2 mr-1"></i>
                      <div>
                        <h5>Game-Genre</h5>
                        <ul className="list-none p-0 filter_options  d-flex mb-0 flex-wrap">
                          {gameGenreArray?.map((game, i) => (
                            <li
                              key={i}
                              onClick={() =>
                                handleFilterChange("gameGenre", game)
                              }
                              className={
                                selectedFilter.gameGenre.includes(game)
                                  ? "mb-2 me-2 active"
                                  : "mb-2 me-2"
                              }
                            >
                              {game}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </a>
                    <a className="dropdown-item mb-4">
                      <i className="flaticon-bell-fill-2 mr-1"></i>
                      <div>
                        <h5>Interests</h5>
                        <ul className="list-none p-0 filter_options  d-flex mb-0 flex-wrap">
                          {interestsArray?.map((interest, i) => (
                            <li
                              key={i}
                              onClick={() =>
                                handleFilterChange("interests", interest)
                              }
                              className={
                                selectedFilter.interests.includes(interest)
                                  ? "mb-2 me-2 active"
                                  : "mb-2 me-2"
                              }
                            >
                              {interest}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </a>
                    <a className="dropdown-item mb-4">
                      <i className="flaticon-bell-fill-2 mr-1"></i>
                      <div>
                        <h5>Subscriber </h5>
                        <ul className="list-none p-0 filter_options  d-flex mb-0 flex-wrap">
                          {subscription?.map((sub, i) => (
                            <li
                              key={i}
                              onClick={() =>
                                handleFilterChange(
                                  "subscription",
                                  sub[Object.keys(sub)[0]]
                                )
                              }
                              className={
                                selectedFilter.subscription.includes(
                                  sub[Object.keys(sub)[0]]
                                )
                                  ? "mb-2 me-2 active"
                                  : "mb-2 me-2"
                              }
                            >
                              {Object.keys(sub)[0]}
                            </li>
                          ))}
                          {/* <li className="mb-2 me-2"> Subscribed </li>
                          <li className="mb-2 me-2 "> Not Subscribed </li> */}
                        </ul>
                      </div>
                    </a>
                    <a className="dropdown-item mb-4">
                      <i className="flaticon-bell-fill-2 mr-1"></i>
                      <div>
                        <a className="dropdown-item mb-4 select_option_filters">
                          <h5>Games </h5>
                          <ul className="list-none p-0 filter_options  d-flex mb-0 flex-wrap">
                            <Select
                              isMulti
                              onChange={handleGameSelect}
                              options={gamelist?.map((game) => ({
                                value: game?._id,
                                label: game?.title,
                              }))}
                              value={gameselectVal}
                            />

                            {/* {gamelist?.map((game, i) => {
                            return(
                              <li
                              key={i}
                              onClick={() =>
                                handleFilterChange(
                                  "game",
                                  game[Object.keys(game)[0]]
                                )
                              }
                              className={
                                selectedFilter.game.includes(
                                  game[Object.keys(game)[0]]
                                )
                                  ? "mb-2 me-2 active"
                                  : "mb-2 me-2"
                              }
                            >
                              {Object.values(game)[2]}
                            </li>

                            )
                            
                          })} */}
                            {/* <li className="mb-2 me-2"> Gamers Names 1 </li>
                          <li className="mb-2 me-2 "> Gamers Names 2</li> */}
                          </ul>
                        </a>
                      </div>
                    </a>
                  </div>
                  {/* <div className="d-flex justify-content-center align-items-center p-4">
                    <button
                      onClick={applyFilter}
                      className="btn btn-light-dark"
                    >
                      Apply
                    </button>
                  </div> */}
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-center align-items-center ">
                    <button
                      onClick={applyFilter}
                      className="btn btn-light-dark"
                    >
                      Apply
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Surname</th>
                      <th>Gamer Name</th>
                      <th>Email</th>
                      <th>Phone No</th>
                      <th>Verification</th>
                      <th>Country</th>
                      <th>Gender</th>
                      <th>Status</th>
                      <th>Subscription</th>
                      <th style={{ paddingLeft: "65px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList
                      ?.filter(
                        (data) =>
                          data?.first_name
                            ?.toLowerCase()
                            .includes(search.toLowerCase()) ||
                          data?.email
                            ?.toLowerCase()
                            .includes(search.toLowerCase()) ||
                          data?.gamer_name
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                        /* data.first_name == undefined ?  data.first_name :
                        data.first_name?.toLowerCase().includes(search) */
                      )
                      ?.slice(resultOffset, resultOffset + page)
                      ?.map(function (userobj, i) {
                        return (
                          <tr key={i}>
                            <td>{resultOffset + 6 - 5 + i}</td>

                            <td>
                              <a
                                onClick={() =>
                                  handleUserDetailPage(userobj._id)
                                }
                                className="cursor-pointer"
                              >
                                {userobj.first_name}
                              </a>
                            </td>

                            <td> {userobj.sur_name}</td>

                            <td>
                              <a
                                onClick={() =>
                                  handleUserDetailPage(userobj._id)
                                }
                                className="cursor-pointer"
                              >
                                {userobj.gamer_name
                                  ? userobj.gamer_name
                                  : "N/A"}
                              </a>
                            </td>

                            <td>{userobj.email}</td>

                            <td>
                              {userobj.country_code}{" "}
                              {userobj.phone_number
                                ? userobj.phone_number
                                : "N/A"}
                            </td>

                            <td>
                              <span
                                className={
                                  userobj.admin_approve == 1
                                    ? "shadow-none badge badge-primary"
                                    : "shadow-none badge badge-warning"
                                }
                              >
                                {" "}
                                {userobj.admin_approve == 1
                                  ? "Verified"
                                  : "Unverified"}
                              </span>
                            </td>

                            <td>{userobj.country ? userobj.country : "N/A"}</td>

                            <td>
                              {userobj?.gender
                                && userobj?.gender == 1
                                  ? "Male"
                                  : userobj?.gender == 0
                                  ? "Female"
                                  : userobj?.gender == 2
                                  ? "Other"
                                  : "N/A"
                                }
                            </td>

                            <td>
                              <p
                                className={
                                  userobj.status == 0
                                    ? "shadow-none badge badge-primary"
                                    : userobj.status == 1
                                    ? "badge badge-secondary"
                                    : "badge badge-danger"
                                }
                              >
                                {userobj.status == 0
                                  ? "Active"
                                  : userobj.status == 1
                                  ? "Temporarily banned"
                                  : "Banned"}
                              </p>
                            </td>

                            <td>
                              {" "}
                              <div class="form-check form-switch">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                  checked={
                                    userobj?.is_subscribed == 1 ? true : false
                                  }
                                  onChange={() =>
                                    dispatch(
                                      handleSubscription({
                                        user_id: userobj?._id,
                                      })
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                className="btn-group usermgt_action"
                                role="group"
                              >
                                <button
                                  id="btndefault1"
                                  type="button"
                                  className="btn dropdown-toggle btn-light-dark _effect--ripple waves-effect waves-light "
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  {userobj.status == 0
                                    ? "Active"
                                    : userobj.status == 1
                                    ? "Temporarily banned"
                                    : "Banned"}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-chevron-down"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </button>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="btndefault1"
                                  style={{
                                    position: "absolute",
                                    inset: "0px auto auto 0px",
                                    margin: "0px",
                                    transform: "translate(0px, 41px)",
                                  }}
                                  data-popper-placement="bottom-start"
                                >
                                  <a
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        Userstatusupdate({
                                          id: userobj._id,
                                          status: 1,
                                        })
                                      ).then(function () {
                                        dispatch(Userlist());
                                      });
                                    }}
                                  >
                                    Temporarily banned
                                  </a>
                                  <a
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        Userstatusupdate({
                                          id: userobj._id,
                                          status: 2,
                                        })
                                      ).then(function () {
                                        dispatch(Userlist());
                                      });
                                    }}
                                  >
                                    Banned
                                  </a>
                                  <a
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => {
                                      dispatch(
                                        Userstatusupdate({
                                          id: userobj._id,
                                          status: 0,
                                        })
                                      ).then(function () {
                                        dispatch(Userlist());
                                      });
                                    }}
                                  >
                                    Active
                                  </a>
                                  <a
                                    className="dropdown-item cursor-pointer"
                                    onClick={() => {
                                      handleShow(userobj.email);
                                    }}
                                  >
                                    Write Email
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {userList?.length > 0 && (
          <div className="paginating-container pagination-solid">
            <ReactPaginate
              activeClassName="active"
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              previousLabel="Prev"
              renderOnZeroPageCount={null}
              className="pagination"
            />
          </div>
        )}
      </div>
      {/* <div
        className="dataTables_paginate paging_simple_numbers"
        id="zero-config_paginate"
      >
            <ul className="pagination">
           
            </ul>
      </div> */}

      <Modal show={show} onHide={handleClose}>
        <div className="modal-content email_content">
          <div className="modal-header">
            <h5
              className="modal-title add-title"
              id="notesMailModalTitleeLabel"
            >
              Write Email
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                handleClose();
              }}
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div className="modal-body">
            <input
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              name="post_title"
              type="text"
              className="form-control"
              id="post-title"
              placeholder="Subject"
            />

            <ReactQuill
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ indent: "-1" }, { indent: "+1" }],

                  [{ color: [] }],
                ],
              }}
              value={value}
              onChange={setValue}
            />
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              onClick={submitform}
              className="btn float-left drk_btn"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </Master>
  );
}
