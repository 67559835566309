import React from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
const PieCharts = ({ series, labels }) => {
  const options = {
    labels: labels,

    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    dataLabels: { enabled: false },
    // tooltip: { enabled: false },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          // size: "75%",
          labels: {
            show: true,
            name: { show: true },
            total: {
              show: true,
              showAlways: true,
              // formatter: function (w) {
              //   const totals = w.globals.seriesTotals;

              //   const result = totals.reduce((a, b) => a + b, 0);

              //   return (result / 1000).toFixed(3);
              // }
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="donut" />
    </div>
  );
};

export default PieCharts;
