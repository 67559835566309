import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Master from '../../Include/Master'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {viewBlogList}  from '../../../Redux/Action/AdminPannel'
import draftToHtml from "draftjs-to-html";
import parse from "html-react-parser";
import Moment from '../../../Utils/MomentDate'
const ViewBlogPage = () => {
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
    const {id}=useParams()
    const dispatch=useDispatch();
    const viewBlog=useSelector((state)=>state?.AdminReducer?.view_blog)
    useEffect(() => {
        dispatch(viewBlogList(id))
    }, [])

    const options = {
        replace: (DOMParser) => {
          if (DOMParser.attribs && DOMParser.attribs.class === "remove") {
            return <div></div>;
          }
        },
      };
  return (
     <Master>
        <div className="middle-content container-xxl p-0">
                    
                    {/* <!-- BREADCRUMB --> */}
                    <div className="page-meta mb-4">
                        <nav className="breadcrumb-style-one" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="blogs.html">Blogs</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Blog Details</li>
                            </ol>
                           
                        </nav>
                    </div>

                   {/* <!-- Add Button Modal --> */}
                   
                    {/* <!-- <div className="row layout-top-spacing mb-2">
                        <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
                            <input id="t-text" type="text" name="txt" placeholder="Search" className="form-control" required="">
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 text-sm-right text-center align-self-center">
                            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
                                <div className=" d-flex justify-content-end me-4">
                                    <button className="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btn-add-contact">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                </div>
                                <div className="btn-group  mb-2 " role="group ml-2">
                                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                    <div className="dropdown-menu" aria-labelledby="btndefault">
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-gear-fill mr-1"></i>Present</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> --> */}
                
    {
        viewBlog && (
            <div className="row blog_detail_page">

<div className="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-6 mb-4">
   
        <h2 className="card-title mb-2">{viewBlog?.post_title}</h2>
     
        <div className="media-body d-flex justify-content-between">
            <ul className="list-inline d-flex">
                <li>Posted: <span className="text-theme-color-2 me-3">{Moment.Date(viewBlog?.createdAt)}</span></li>
                <li>By: <span className="text-theme-color-2">Admin</span></li>                                       
            </ul>
        </div>
        <img src={IMG_URL+ "blogimage/"+  viewBlog?.image} className="card-img-top" alt="..." />
        <div className="card-body px-0 pb-0">
           
            <div className="media mt-4 mb-0 pt-1">
               
                <div className="media-body">
                {
                    viewBlog?.content && parse(
                       viewBlog?.content,
                        options,
                    )
                   
                    
                }
                    {/* <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p>

                    <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p>

                    <h3 className="mb-4 mt-4">Sub-Heading</h3>

                    <ul className="blog_listing">
                        <li className="mb-2"><b>Lorem ipsum dolor:</b> 
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p></li>
                       
                        <li className="mb-2"><b>Lorem ipsum dolor:</b> 
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p></li>

                        <li className="mb-2"><b>Lorem ipsum dolor:</b> 
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p></li>

                        <li className="mb-2"><b>Lorem ipsum dolor:</b> 
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p></li>
                    </ul>

                    <h3 className="mb-4 mt-4">Conclusion</h3>
                    <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!Lorem ipsum dolor sit amet consectetur adipisicing elit. Error commodi, ducimus aut, itaque eligendi delectus excepturi molestiae nam tempora officia modi minima aperiam cupiditate vitae quas, quod eaque ullam atque!</p> */}
                    
                </div>
            </div>
        </div>
       
</div>
</div>
        )
    }
                    
                
                </div>     
     </Master>
  )
}

export default ViewBlogPage