import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getConvo } from "../../Redux/Action/AdminPannel";

const ViewModal = ({
  setBothUsers,
  viewVal,
  setViewVal,
  userList,
  setShowChatBox,
}) => {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState();
  const [secondUsers, setSecondUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState({
    firstUser: "",
    secondUser: "",
  });
  const dispatch = useDispatch();

  console.log(users,"uusueueuururuuru")

  const handleClose = () => {
    setShow(false);
    setViewVal(false);
    setSecondUsers(null);
  };

  useEffect(() => {
    setShow(viewVal);
  }, [viewVal]);

  useEffect(() => {
    if (userList?.length > 0) {
      setUsers(
        userList?.map((user) => {
          // console.log(user);
          // return {
          //   label: user?.first_name.concat(" ", user?.sur_name),
          //   value: user?._id,
          // };
          return {
            label: user?.gamer_name,
            value: user?._id,
          };
        })
      );
    }
  }, [userList]);

  const handleViewChat = () => {
    handleClose();
    if (selectedUsers?.firstUser != "" && selectedUsers?.secondUser != "") {
      dispatch(getConvo(selectedUsers));
    }
    const bothUsers = {
      firstUser: userList?.filter(
        (user) => user?._id == selectedUsers?.firstUser
      )[0],
      secondUser: userList?.filter(
        (user) => user?._id == selectedUsers?.secondUser
      )[0],
    };

    setBothUsers(bothUsers);
    setShowChatBox(true);
  };

  const selectFirstUser = (e) => {
    setSelectedUsers((prev) => {
      return {
        ...prev,
        firstUser: e.value,
      };
    });
    setSecondUsers(users?.filter((user) => user?.value !== e?.value));
  };
  const selectSecondUser = (e) => {
    setSelectedUsers((prev) => {
      return {
        ...prev,
        secondUser: e?.value,
      };
    });
  };

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure dropdown is above other elements
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure dropdown options are visible
    }),
  };

  

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={handleClose} className="chat_modal">
        <Modal.Header closeButton>
          <Modal.Title>View Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal_inner_content">
            {users && (
              <div className="row">
                <div className="col-md-6 select_option_filters">
                  <div   className="user-label">First User</div>
                  <Select  styles={customStyles}  options={users} menuPortalTarget={document.body}  onChange={selectFirstUser} />
                </div>
                <div className="col-md-6 select_option_filters">
                  <div className="user-label">Second User</div>
                  {secondUsers && (
                    <Select styles={customStyles} options={secondUsers} menuPortalTarget={document.body} onChange={selectSecondUser} />
                  )}
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn drk_btn " onClick={handleViewChat}>
            View Chat
          </Button>
          <Button
            variant="btn btn-light-dark"
            onClick={handleClose}
            className=""
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewModal;
