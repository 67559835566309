import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import {
  addNotifications,
  getNotifications,
} from "../../Redux/Action/AdminPannel";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import DeleteModal from "../DeleteModal/DeleteModal";
const Notification = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [show, setShow] = useState(false);
  const [revList, setRevList] = useState("")
  const [delId, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const dispatch = useDispatch();
  const notifyList = useSelector((state) => state?.AdminReducer?.notify_list);
  useEffect(()=>{
    const listReverse=notifyList.reverse()
    if(listReverse){
      setRevList(listReverse)
    }
  },[notifyList])
  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Enter title";
    }
    if (!values.description) {
      errors.description = "Write some content";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      gender: "",
      title: "",
      description: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        await dispatch(addNotifications(values));
        dispatch(getNotifications());
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % notifyList.length);
  };
  
  useEffect(() => {
    if (page && notifyList) {
      setPageCount(Math.ceil(notifyList.length / page));
    }
  }, [notifyList, page]);


  const deleteNotify = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Notification</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end">
                <button
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setShow(true);
                    formik.resetForm();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll_notification">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>To</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {revList && revList
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((data) =>
                        data?.title.toLowerCase().includes(search)
                      )
                      ?.map((item, i) => {
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>{item?.title}</td>
                            <td>
                              <p className="line_break">{item?.description}</p>
                            </td>

                            <td>
                              {item.gender && item.gender == 1
                                ? "All Male"
                                : item.gender == 3
                                ? "All Users"
                                  : item.gender == 0 
                                  ? "All Female" 
                                 : item.gender == 2
                                      && "All Other" }
                            </td>
                            <td className="custom_pd">
                              {/* <a className="delete_ml">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a> */}

                              <a onClick={() => deleteNotify(item._id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
          activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>
      <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"notification"}
        />
      {/* <!-- Modal --> */}
      <Modal show={show} onHide={!show}>
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Notification
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <select
                      id=""
                      className="form-control"
                      onChange={(e) =>
                        formik.setFieldValue("gender", e.target.value)
                      }
                    >
                      <option value="" selected>
                        Select Users
                      </option>
                      <option value="1">All Male</option>
                      <option value="0">All Female</option>
                      <option value="2">All Other</option>
                      <option value="3">All Users</option>
                     
                    </select>
                    <span className="validation-text"></span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Title"
                    />
                    <span className="validation-text">
                      {formik.errors.title ? (
                        <p style={{ color: "red" }}>{formik.errors.title}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <textarea
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      name="description"
                      id=""
                      cols="30"
                      rows="4"
                      className="form-control"
                      placeholder="Description"
                    ></textarea>
                    <span className="validation-text">
                      {formik.errors.description ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.description}*
                        </p>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {/* <!-- <button className="btn btn btn-light-dark" data-bs-dismiss="modal"><i className="flaticon-cancel-12"></i> Discard</button> --> */}

            {formik.values.title && formik.values.description ? (
              <button
                type="submit"
                className="btn drk_btn"
                onClick={() => setShow(false)}
              >
                Notify
              </button>
            ) : (
              <button type="submit" className="btn drk_btn">
                Notify
              </button>
            )}
          </div>
        </form>
      </Modal>
    </Master>
  );
};

export default Notification;
