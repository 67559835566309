import moment from "moment";

const Moment={
    Date:(date)=>{
        if(date){
            return moment(date).format("DD/MM/YYYY")
        }

    }
}
export default Moment;