import {
  ADVERTISE_LIST,
  BLOG_LIST,
  DASHBOARD_LIST,
  DELETED_ACC_LIST,
  DELETE_LIST,
  NOTIFICATION_LIST,
  PRIZE_LIST,
  QUEST_LIST,
  REPORT_LIST,
  VIEW_BLOG,
  WEEKLY_BOX,
  CHAT_USER_LIST,
  USER_CHAT,
  USER_CONVO_LIST,
  VIEW_USER,
  USERS_CONVO,
  ANALYTIC_LIST,
  COUNTRY_LIST,
  INTEREST_LIST,
  AGE_AVERAGE_LIST,
  ORIGIN_LIST,
  INTEREST_DATA_LIST,
  DELETE_ACCOUNT_LIST,
  ADD_ADVER_SUCCESS,
  UPDATE_ADVER_SUCCESS,
  Game_LIST,

  POOL_LIST,
  number_per_user,
  averageTimeUser
} from "../Constant";

const initialState = {
  dashboard: [],
  weekly_box: [],
  quest_list: [],
  blog_list: [],
  view_blog: [],
  advertise_list: [],
  report_list: [],
  notify_list: [],
  prize_list: [],
  delete_list: [],
  user_view: null,
  adminToken: null,
  chatUserList: null,
  userChat: null,
  userConvoList: null,
  userConvo: null,
  analytic_list: [],
  country_list: [],
  interest_list: [],
  age_list: [],
  origin_list: [],
  int_data_list: [],
  deleted_account_list: [],
  game_list:[],
  pool_list:[],
  number_per_user:[],
  avgtimeofuserondashboard:null

};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_LIST: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }

    case USERS_CONVO: {
      return {
        ...state,
        userConvo: action.payload,
      };
    }

    case CHAT_USER_LIST: {
      return {
        ...state,
        chatUserList: action.payload,
      };
    }
    case USER_CONVO_LIST: {
      return {
        ...state,
        userConvoList: action.payload,
      };
    }
    case USER_CHAT: {
      return {
        ...state,
        userChat: action.payload,
      };
    }

    case WEEKLY_BOX: {
      return {
        ...state,
        weekly_box: action.payload,
      };
    }
    case QUEST_LIST: {
      return {
        ...state,
        quest_list: action.payload,
      };
    }
    case BLOG_LIST: {
      return {
        ...state,
        blog_list: action.payload,
      };
    }
    case VIEW_BLOG: {
      return {
        ...state,
        view_blog: action.payload,
      };
    }
    case ADVERTISE_LIST: {
      return {
        ...state,
        advertise_list: action.payload,
      };
    }
    // case DELETE_LIST: {
    //   return {
    //     ...state,
    //     blog_list:state.blog_list.filter(item => item !== action.payload)
    //   }
    // }

    case REPORT_LIST: {
      return {
        ...state,
        report_list: action.payload,
      };
    }
    case NOTIFICATION_LIST: {
      return {
        ...state,
        notify_list: action.payload,
      };
    }
    case PRIZE_LIST: {
      return {
        ...state,
        prize_list: action.payload,
      };
    }
    case DELETED_ACC_LIST: {
      return {
        ...state,
        delete_list: action.payload,
      };
    }
    case VIEW_USER: {
      return {
        ...state,
        user_view: action.payload,
      };
    }
    case ANALYTIC_LIST: {
      return {
        ...state,
        analytic_list: action.payload,
      };
    }
    case COUNTRY_LIST: {
      return {
        ...state,
        country_list: action.payload,
      };
    }
    case INTEREST_LIST: {
      return {
        ...state,
        interest_list: action.payload,
      };
    }
    case AGE_AVERAGE_LIST: {
      return {
        ...state,
        age_list: action.payload,
      };
    }
    case ORIGIN_LIST: {
      return {
        ...state,
        origin_list: action.payload,
      };
    }
    case INTEREST_DATA_LIST: {
      return {
        ...state,
        int_data_list: action.payload,
      };
    }
    case DELETE_ACCOUNT_LIST: {
      return {
        ...state,
        deleted_account_list: action.payload,
      };
    }
    case number_per_user: {
      return {
        ...state,
        number_per_user: action.payload,
      };
    }
    case ADD_ADVER_SUCCESS: {
      return {
        ...state,
        advertise_list: [...state.advertise_list, action.payload],
      };
    }
    case POOL_LIST: {console.log(action.payload)
      return {
        ...state,
        pool_list: [action.payload],
      };
    }
    case averageTimeUser: {console.log(action.payload)
      return {
        ...state,
        avgtimeofuserondashboard: [action.payload],
      };
    }
    case UPDATE_ADVER_SUCCESS:
      return {
        ...state,
        advertise_list: [...state.advertise_list, action.payload],
      };
      case Game_LIST:
        return {
          ...state,
          game_list: action.payload,
        };
        
    default:
      return state;
  }
};

export default AdminReducer;
