import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminApprove,
  Userlist,
  Writeemail,
} from "../../Redux/Action/LoginAction";
import Master from "../Include/Master";
import { Link } from "react-router-dom";
import UserVerificationModal from "./UserVerificationModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
export default function Userverification() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Userlist());
  }, []);
  const userlist = useSelector((state) => state.Loginreduce.user_list);

  const [value, setValue] = useState("");
  const [email, setemail] = useState("");
  const [viewVal, setViewVal] = useState(false);
  const [show, setShow] = useState(false);
  const [userObject, setUserObject] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [delId, setDeleteId] = useState();
  const [list, setList] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (email) => {
    setShow(true);
    setemail(email);
  };

  const handleUserClick = (user) => {
    setViewVal(true);
    setUserObject(user);
  };

  const handleUserVerify = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  var values = {};
  const submitform = () => {
    if (value == "") {
      setShow(true);
    } else {
      values.content = value;
      values.email = email;
      values.userreject = 3;
      dispatch(Writeemail(values)).then(function () {
        dispatch(Userlist());
        setShow(false);
        setValue("");
      });
    }
  };
  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % list.length);
  };
  useEffect(() => {
    const data = userlist.filter(
      (item, i) =>
        item.front_image != null &&
        item?.back_image != null &&
        item.selfie != null &&
      (  item?.admin_approve ==0 )
        
        
    );
    setList(data);
  }, [userlist]);
  useEffect(() => {
    if (page && list) {
      setPageCount(Math.ceil(list.length / page));
    }
  }, [list, page]);

  return (
    <Master>
      <div className="layout-px-spacing">
        <div className="middle-content container-xxl p-0">
          <div className="page-meta">
            <nav className="breadcrumb-style-one" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">User Verification</a>
                </li>
              </ol>
            </nav>
          </div>
          {userObject && (
            <UserVerificationModal
              userObject={userObject}
              viewVal={viewVal}
              setViewVal={setViewVal}
              setUserObject={setUserObject}
              handleReply={handleShow}
            />
          )}
          <DeleteModal
            show={deleteShow}
            data={delId}
            setShowVal={setDeleteShow}
            modalName={"userAccept"}
          />
          <div className="row layout-top-spacing">
            <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
              <div className="widget-content widget-content-area br-8">
                <div
                  id="zero-config_filter"
                  className="dataTables_filter searchbar "
                >
                  <div className="position-relative mb-4">
                    <input
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      type="search"
                      className="form-control"
                      placeholder="Search"
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-search"
                    >
                      <circle cx="11" cy="11" r="8"></circle>
                      <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg>
                  </div>
                </div>
                <div className="table-responsive custom_scroll">
                  <table
                    id="zero-config"
                    className="table table-striped dt-table-hover user_verfication_table"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Id Card</th>
                        <th>Back View</th>
                        <th>Selfie</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list
                        ?.slice(resultOffset, resultOffset + page)
                        ?.filter((data) =>
                          data?.first_name?.toLowerCase().includes(search)
                        )
                        ?.map((user_object, i) => {
                          return (
                            <tr key={i}>
                              <td>{resultOffset + 6 - 5 + i}</td>
                              <td>
                                <Link
                                  onClick={() => handleUserClick(user_object)}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1"
                                >
                                  {user_object.first_name}
                                </Link>
                              </td>
                              <td> {user_object.sur_name}</td>
                              <td>
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      "user_docs/" +
                                      user_object.front_image
                                    }
                                    className="  profile-img h-60"
                                    alt="avatar"
                                  />
                                </span>
                              </td>
                              <td>
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      "user_docs/" +
                                      user_object.back_image
                                    }
                                    className=" profile-img h-60"
                                    alt="avatar"
                                  />
                                </span>
                              </td>

                              <td>
                                <span>
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      "user_docs/" +
                                      user_object.selfie
                                    }
                                    className=" profile-img h-60"
                                    alt="avatar"
                                  />
                                </span>
                              </td>

                              <td className="request_accepted">
                                {user_object.admin_approve == 1 ? (
                                  <button className="btn drk_btn user_acc_size">
                                    Accepted
                                  </button>
                                ) : (
                                  <button
                                    className="btn drk_btn user_acc_size"
                                    onClick={() =>
                                      handleUserVerify(user_object._id)
                                    }
                                  >
                                    Accept
                                  </button>
                                )}

                                {user_object.admin_approve != 1 && (
                                  <button
                                    className="btn btn-light-dark user_acc_size_dark ms-2"
                                    onClick={() => {
                                      handleShow(user_object.email);
                                    }}
                                  >
                                    Reject
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
          activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Reason for Rejection
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleClose();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <form action="">
              <div className="row layout-spacing layout-top-spacing">
                <ReactQuill
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ indent: "-1" }, { indent: "+1" }],
                      [{ color: [] }],
                    ],
                  }}
                  value={value}
                  onChange={setValue}
                  placeholder="Type here...."
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn drk_btn"
                  onClick={() => {
                    submitform();
                  }}
                >
                  Reply
                </button>
                <button
                  type="button"
                  className="btn btn btn-light-dark"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </Master>
  );
}
