import React, { useMemo } from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import Moment from "../../Utils/MomentDate";
import Moment2 from "../../Utils/Moment";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Form from "react-bootstrap/Form";
import {
  addDataWeeklyBox,
  addPool,
  dataWeeklyBox,
  editPool,
  getDataQuest,
  getPool,
  liveGoStatus,
} from "../../Redux/Action/AdminPannel";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { poolUserList, Userlist } from "../../Redux/Action/LoginAction";
import axios from "axios";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import continentsData from "./Continents";
var today = new Date().toISOString().split("T")[0];

const Pool = () => {
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  // const userlist = useSelector((state) => state.Loginreduce.user_list);
  const List = useSelector((state) => state.AdminReducer.pool_list);
  console.log(List[0], "tyrtyrtyrtyrtyrt");

  const [search, setSearch] = useState("");
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(false);
  const [flag, setFlag] = useState(false);
  const [country, setCountry] = useState([]);
  const [countryerr, setCountryerr] = useState(null);
  const [page, setPage] = useState(6);
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [delId, setDeleteId] = useState();
  const [deleteShow, setDeleteShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedValueerr, setSelectedValueerr] = useState(null);
  const [poolName, setpoolName] = useState(null);
  const [poolNameerr, setpoolNameerr] = useState(null);
  const [resultOffset, setResultOffset] = useState(0);
  const [userlist, setuserlist] = useState(null);

  const options = userlist?.map((item) => ({
    id: item?._id,
    name: item?.gamer_name || "",
  }));

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(poolUserList());
      setuserlist(data?.data);
    };
    fetchData();
  }, []);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
    setSelectedValueerr("");
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
  };

  useEffect(() => {
    dispatch(getPool({ search: search }));
  }, [dispatch, search, resultOffset, flag]);

  const handlePageClick = (e) => {
    console.log(e);
    setResultOffset((e.selected * page) % List[0].length);
  };

  useEffect(() => {
    if (page && List) {
      setPageCount(Math.ceil(List[0]?.length / page));
    }
  }, [List, page]);

  const validation = () => {
    let isvalid = true;

    if (!poolName) {
      // setpoolNameerr("Required*");
      toast.error("Pool Name is Required");
      isvalid = false;
    } else if (country?.length == 0 && selectedValue?.length == 0) {
      // setSelectedValueerr("Required*");
      toast.error("Please Select Pool Member/Continent");
      isvalid = false;
    }
    //  else if (selectedValue?.length == 0 && country?.length == 0) {
    //   setCountryerr("Required*");
    //   isvalid = false;
    // }
    if (isvalid) {
      setSelectedValue([]);
      setpoolName("");

      let member = selectedValue.map((x) => x?.id);
      console.log(edit);

      setShow(false);
      if (edit) {
        console.log("======================================");

        dispatch(
          editPool(editId, {
            title: poolName,
            members: member,
            countries: country,
          })
        ).then((res) => {
          if (res.data.status == 200) {
            // dispatch(());
            // dispatch({
            //   type: POOL_LIST,
            //   payload: res.data.data,
            // });
            setFlag(!flag);
            setEdit(false);
            setpoolName(null);
            setCountry([]);
            setShow(false);
            setSelectedValue([]);
          }
        });
      } else {
        console.log("add");
        dispatch(
          addPool({ title: poolName, members: member, countries: country })
        ).then((res) => {
          if (res.data.status == 200) {
            // dispatch(());
            // dispatch({
            //   type: POOL_LIST,
            //   payload: res.data.data,
            // });
            setFlag(!flag);
            setpoolName(null);
            setCountry([]);
            setShow(false);
            setSelectedValue([]);
          }
        });
      }
    }
  };

  useEffect(() => {
    dispatch(Userlist());
  }, []);

  // useEffect(() => {
  // setOptions(userlist);

  // const options = data.map(item => ({
  //   value: item._id,
  //   label: item.name
  // }));
  // }, [userlist]);

  // console.log(options)

  const handleDelete = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const handlecountry = (e) => {
    setCountry((old) => {
      if (e.target.checked) {
        return [...old, e.target.value];
      } else {
        return old.filter((country) => country !== e.target.value);
      }
      // return [...old, e.target.value]; // Return the updated state value
    });
  };

  // const handleCountryChange = (event) => {
  //   const { value, checked } = event.target;
  //   setSelectedCountries((prevSelectedCountries) => {
  //     if (checked) {
  //       // Add the selected country to the list
  //       return [...prevSelectedCountries, value];
  //     } else {
  //       // Remove the selected country from the list
  //       return prevSelectedCountries.filter((country) => country !== value);
  //     }
  //   });
  // };

  console.log(country, "country");

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Pool</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end ">
                <button
                  className="me-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                {/* <div className="search-label-pool">
                  Search users by gamer name
                </div> */}
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Pool Name</th>
                      <th>Pool Members</th>
                      <th>Actions</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="height_m">
                    {List[0]
                      ?.slice(resultOffset, resultOffset + page)
                      ?.map((item, i) => {
                        console.log(item, "pool");
                        return (
                          <tr>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>{item?.title}</td>
                            <td>
                              <div
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                                className="subscriber"
                                // onClick={handleShow}
                              >
                                {item?.members?.length}
                              </div>
                            </td>
                            {/* <td>3 days</td> */}

                            <td className="custom_pd">
                              <a
                                onClick={() => {
                                  setEditId(item?._id);
                                  setEdit(true);
                                  setShow(true);
                                  setpoolName(item?.title);
                                  const country = item?.countries?.map(
                                    (country) => {
                                      return country;
                                    }
                                  );
                                  setCountry(country);
                                  const members = item?.members?.map(
                                    (member) => ({
                                      name: member?.gamer_name,
                                      id: member?._id,
                                    })
                                  );

                                  setSelectedValue(members);
                                }}
                                // data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="delete_ml"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="22"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-edit"
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </a>

                              <a onClick={() => handleDelete(item?._id)}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  className="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </a>
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={6}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>

      <DeleteModal
        show={deleteShow}
        data={delId}
        setShowVal={setDeleteShow}
        modalName={"pool"}
      />

      <Modal show={show} onHide={!show}>
        <form
          class="modal-content"
          // onSubmit={formik.handleSubmit}
        >
          <div class="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {edit ? "Edit Pool" : "Add New Pool"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                // setShow(false);
                setpoolName(null);
                setCountry([]);
                setShow(false);
                setSelectedValue([]);

                // formik.resetForm();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div></div>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <input
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Pool Name"
                      value={poolName}
                      onChange={(e) => {
                        setpoolName(e.target.value);
                        setpoolNameerr("");
                      }}
                    />
                    <div className="text-danger">{poolNameerr}</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <>
                    <div className="contact-email">
                      <label>Pool Member</label>

                      <Multiselect
                        options={options}
                        selectedValues={selectedValue}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        valueField="id"
                      />
                    </div>
                    <div className="text-danger">{selectedValueerr}</div>
                  </>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <>
                    <div className="contact-email">
                      {/* <label>Continents</label> */}

                      {/* <Continents  /> */}

                      <div>
                        <label htmlFor="continent-select">
                          Select a Continent:
                        </label>
                        <div className="extra-data">
                          {continentsData.map((ele) => {
                            return (
                              <>
                                <h1>{ele.continent}</h1>
                                <>
                                  <div id="continent-select">
                                    {ele?.countries.map((item, index) => (
                                      <div key={index}>
                                        <input
                                          type="checkbox"
                                          // name="country"
                                          // id={`checkbox-${index}`}
                                          value={item}
                                          onChange={handlecountry}
                                          checked={country?.includes(item)}
                                        />
                                        <label>{item}</label>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="text-danger">{countryerr}</div>
                  </>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                setpoolName(null);
                setCountry([]);
                setShow(false);
                setSelectedValue([]);
              }}
            >
              <i className="flaticon-cancel-12"></i> Discard
            </button>

            <button
              type="submit"
              className="btn drk_btn"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                validation();
                // setShow(false)
              }}
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </Master>
  );
};

export default Pool;
