// import React, { useEffect } from "react";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import { EditorState, convertToRaw, convertFromRaw,  convertFromHTML, ContentState, } from "draft-js";
// import { useState } from "react";
// const RTextAditor = ({ htmlHandle, givenContent }) => {

//   const [editorState, setEditorState] = useState(EditorState.createEmpty());

//   useEffect(() => {
//     if (givenContent) {
//       const blocksFromHTML = convertFromHTML(givenContent);
//       const contentState = ContentState.createFromBlockArray(blocksFromHTML);
//       const newEditorState = EditorState.createWithContent(contentState);
//       setEditorState(newEditorState);
//     }
//   }, [givenContent]);

//   const [description, setDescription] = useState({
//     htmlValue: "<p></p>\n",
//     // editorState: EditorState.createWithContent(
//     //   convertFromRaw(JSON.parse(givenContent))
//     // ),
//     editorState,
//   });
//   const onEditorStateChange = (editorValue) => {
//     const editorStateInHtml = draftToHtml(
//       convertToRaw(editorValue.getCurrentContent())
//     );
//     setDescription({
//       htmlValue: editorStateInHtml,
//       editorState: editorValue,
//     });
//     htmlHandle(JSON.stringify(convertToRaw(editorValue.getCurrentContent())));
//   };
//   return (
//     <div>
//       <Editor
//         toolbarHidden={false}
//         editorState={description.editorState}
//         onEditorStateChange={onEditorStateChange}
//         toolbarClassName="toolbarClassName"
//         wrapperClassName="wrapperClassName"
//         editorClassName="editorClassName"
//         placeholder="Description..."
//       />
//     </div>
//   );
// };

// export default RTextAditor;



import React, { useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { useState } from "react";

const RTextAditor = ({ htmlHandle, givenContent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Convert HTML content to EditorState when the givenContent prop changes
  useEffect(() => {
    if (givenContent) {
      const blocksFromHTML = convertFromHTML(givenContent);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [givenContent]);

  const onEditorStateChange = (newEditorState) => {
    // Convert EditorState to HTML and update the state
    const editorStateInHtml = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
    setEditorState(newEditorState);
    htmlHandle(editorStateInHtml);
  };

  return (
    <div>
      <Editor
        toolbarHidden={false}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        placeholder="Description..."
      />
    </div>
  );
};

export default RTextAditor;
