import ReactApexChart from "react-apexcharts";

const QuiestPieCharts = ({ series, labels, totalQuests }) => {
    console.log("Total Quests:", totalQuests); // Ensure this logs the correct value
    console.log(labels)

    const options = {
        labels: labels,
        chart: {
            type: "donut",
        },
        legend: {
            show: false,
        },
        dataLabels: { enabled: false },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                        name: { show: true },
                        total: {
                            show: true,
                            showAlways: true,
                            
                            formatter: totalQuests ? ()=>totalQuests :0
                          },
                    },
                },
            },
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="donut" />
        </div>
    );
};

export default QuiestPieCharts;
