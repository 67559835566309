import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const DiscordLogin = () => {
  const userAgent = navigator.userAgent;
  const isAndroid = /Android/i.test(userAgent);
  const isiOS = /(iPhone|iPad|iPod)/i.test(userAgent);

  const [searchParams, setSearchParams] = useSearchParams();
  searchParams.get("code");
  useEffect(() => {
    if (searchParams.get("code")) {
      (async () => {
        await axios
          .get(
            process.env.REACT_APP_API_URL +
              `/discordcallback?code=${searchParams.get("code")}`
          )
          .then((res) => {
            const { data } = res.data;
            // {
            //     "id": "1156917082781384776",
            //     "username": "gamer.1__19915",
            //     "avatar": null,
            //     "discriminator": "0",
            //     "public_flags": 0,
            //     "flags": 0,
            //     "banner": null,
            //     "accent_color": null,
            //     "global_name": "Gamer",
            //     "avatar_decoration_data": null,
            //     "banner_color": null,
            //     "mfa_enabled": false,
            //     "locale": "en-US",
            //     "premium_type": 0,
            //     "email": "info@gamersplayground.gg",
            //     "verified": true
            // }

            const { id, username, global_name, email } = data;
            const params = `?id=${id}&username=${username}&global_name=${global_name}&email=${email}`;
            if (isAndroid) {
              window.location.replace(
                "https://gamendate.com/discord" + params
              );
            }
            if (isiOS) {
              window.location.replace("gamersplayground://game/params" + params);
              // window.location.href = "gamersplayground://game/params" + params;
              // window.location.href = "gamersplayground://wwww.ios.com" + params;
              // window.location.href = "mychat://chat/jane";
            }
          })
          .catch((err) => console.log(err));
      })();
    }
  }, [searchParams.get("code")]);

  return (
    <div>
      {isAndroid && <p>Android</p>}
      {isiOS && <p>Iphone</p>}
      {/* {
        <a
        target="_blank"
          onClick={() => {
            window.location.href = "gamersplayground://game/params";
          }}
        >
          Open
        </a>
      } */}
    </div>
  );
};

export default DiscordLogin;
