import React from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeReport,
  getReportList,
  reportDelete,
} from "../../Redux/Action/AdminPannel";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Userstatusupdate, Writeemail } from "../../Redux/Action/LoginAction";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
var values = {};
function Report() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [show, setShow] = useState(false);

  const [show_description, setShow_description] = useState(false);
  const [value, setValue] = useState("");
  const [description_info, setDescription_info] = useState("");
  const [email, setemail] = useState("");
  const [id_report, setid] = useState("");
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const reportData = useSelector((state) => state.AdminReducer.report_list);
  console.log(reportData, "reportData");
  useEffect(() => {
    dispatch(getReportList());
  }, []);

  const submitform = () => {
    if (value == "") {
      setShow(true);
    } else {
      values.content = value;
      values.email = email;

      values.id = id_report;
      values.type = "report_user";
      values.subject = subject;
      dispatch(Writeemail(values)).then(() => {
        setShow(false);
        setValue("");
        setSubject("");
        dispatch(getReportList());
      });
    }
  };

  const handleEmail = (val) => {
    setemail(val);
    setShow(true);
  };
  const handleTempraryBan = (data_obj) => {
    dispatch(
      Userstatusupdate({
        id: data_obj?.report_to?._id,
        status: 1,
        band: 0,
        id_report: data_obj?._id,
      })
    ).then(() => {
      dispatch(getReportList());
    });

    dispatch();
  };
  const handleBanned = (data_obj) => {
    dispatch(
      Userstatusupdate({
        id: data_obj?.report_to?._id,
        status: 2,
        band: 0,
        id_report: data_obj?._id,
      })
    ).then(() => {
      dispatch(getReportList());
    });
  };

  const handleCloseAction = (id) => {
    dispatch(closeReport(id));
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % reportData.length);
  };
  useEffect(() => {
    if (page && reportData) {
      setPageCount(Math.ceil(reportData.length / page));
    }
  }, [reportData, page]);
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a>Report</a>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}

        {/* <!-- <div className="row layout-top-spacing">
                        <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
                            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
                                <div className=" d-flex justify-content-end me-4">
                                    <button className="" data-bs-toggle="modal" data-bs-target="#exampleModal" id="btn-add-contact">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                                    </button>
                                </div>
                                <div className="btn-group  mb-2 " role="group ml-2">
                                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                    <div className="dropdown-menu" aria-labelledby="btndefault">
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                                        <a href="javascript:void(0);" className="dropdown-item">Present</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> --> */}

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
              <div
                id="zero-config_filter"
                className="dataTables_filter searchbar "
              >
                <div className="position-relative mb-4">
                  <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    className="form-control"
                    placeholder="Search"
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
              <div className="table-responsive custom_scroll">
                <table
                  id="zero-config"
                  className="table table-striped dt-table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Report by</th>
                      <th>Report to</th>
                      <th>Reason</th>
                      <th>Description</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData
                      ?.slice(resultOffset, resultOffset + page)
                      ?.filter((data) =>
                        data?.title.toLowerCase().includes(search)
                      )
                      ?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{resultOffset + 6 - 5 + i}</td>
                            <td>{item?.report_by?.gamer_name}</td>

                            <td>{item?.report_to?.gamer_name}</td>
                            <td

                            //  onClick={()=>{
                            //   setShow_description(true)
                            //   setDescription_info(item?.description)
                            //   }}
                            >
                              <p className="line_break">{item?.title}</p>
                            </td>
                            <td
                              onClick={() => {
                                setShow_description(true);
                                setDescription_info(item?.description);
                              }}
                            >
                              <p className="line_break">
                                {`${item?.description.substring(0, 10)}...`}
                              </p>
                            </td>
                            <td>
                              <p
                                className={
                                  item?.report_to?.status == 1
                                    ? "badge badge-secondary"
                                    : item?.report_to?.status == 2
                                    ? "badge badge-danger"
                                    : "badge badge-warning"
                                }
                              >
                                {item?.status == 1
                                  ? "Warning Mail"
                                  : item?.report_to?.status == 2
                                  ? "Banned"
                                  : item?.report_to?.status == 1
                                  ? "Temporarily banned"
                                  : item?.status == 0
                                  ? "Pending"
                                  : "Warning Mail"}
                              </p>
                            </td>
                            <td>
                              <div
                                className="btn-group  mb-2 me-4"
                                role="group"
                              >
                                <button
                                  id="btndefault1"
                                  type="button"
                                  className="btn dropdown-toggle btn-light-dark _effect--ripple waves-effect waves-light "
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="true"
                                >
                                  {/* {item?.report_to?.status == 0
                                    ? "Active"
                                    : item?.report_to?.status == 1
                                    ? "Temporarily banned"
                                    : "Banned"} */}

                                  {item?.status == 1
                                    ? "Warning Mail"
                                    : item?.report_to?.status == 2
                                    ? "Banned"
                                    : item?.report_to?.status == 1
                                    ? "Temporarily banned"
                                    : item?.status == 0
                                    ? "Pending"
                                    : "Warning Mail"}

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    className="feather feather-chevron-down"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="btndefault1"
                                  style={{
                                    position: "absolute",
                                    inset: "0px auto auto 0px",
                                    margin: "0px",
                                    transform: "translate(0px, 41px)",
                                  }}
                                  data-popper-placement="bottom-start"
                                >
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleTempraryBan(item);
                                    }}
                                  >
                                    Temporarily banned
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleBanned(item);
                                    }}
                                  >
                                    Banned
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    onClick={() => {
                                      handleEmail(item?.report_to?.email);
                                      setid(item?._id);
                                    }}
                                  >
                                    Send Warning Email
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="report_delete_btn">
                                {item?.closed == 1 ? (
                                  <button
                                    onClick={() => {
                                      handleCloseAction(item?._id);
                                    }}
                                    className="btn drk_btn custom_p_btn mr-2"
                                  >
                                    Close the action
                                  </button>
                                ) : (
                                  <button
                                    className="btn drk_btn custom_p_btn mr-2"
                                    disabled
                                  >
                                    Closed
                                  </button>
                                )}
                                <button
                                  onClick={() =>
                                    dispatch(reportDelete(item?._id))
                                  }
                                  className="btn drk_btn custom_p_btn"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                            {/* <td>
                                <button
                                  onClick={() => dispatch(reportDelete(item?._id))}
                                  className="btn drk_btn"
                                >
                                  Delete
                                </button>
                              </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>
      {/* <!-- Modal --> */}
      <Modal show={show} onHide={!show}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Warning Email
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div className="modal-body">
            <input
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              name="post_title"
              type="text"
              className="form-control"
              id="post-title"
              placeholder="Subject"
            />

            <ReactQuill
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  [{ color: [] }],
                ],
              }}
              value={value}
              onChange={setValue}
            />
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              onClick={() => submitform()}
              className="btn drk_btn"
            >
              Reply
            </button>
            <button
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => setShow(false)}
            >
              <i className="flaticon-cancel-12"></i>Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={show_description}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Description
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setShow_description(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>

          <div className="modal-body">
            <textarea readOnly rows="10" cols="60">
              {description_info}
            </textarea>
          </div>
        </div>
      </Modal>
    </Master>
  );
}

export default Report;
