import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { modalHeading, modalBody } from "./ModalData";
import {
  deleteDataWeeklyBox,
  deleteAdvertise,
  deleteDataQuest,
  deleteBlogs,
  DeleteAccount,
  DeleteGameActionV2,
  getPool,
  DeletePool,
} from "../../Redux/Action/AdminPannel";
import {
  CloseSupportupdate,
  GetSupportData,
  AdminApprove,
  Userlist,
  deleteNotification,
} from "../../Redux/Action/LoginAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const DeleteModal = ({ show, setShowVal, modalName, data }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setShowVal(false);
  };

const navigate=useNavigate();

 
  const handleDel = () => {
    // console.log(modalName, data)
    switch (modalName) {
      case "weeklyBox":
        dispatch(deleteDataWeeklyBox(data));
        setShowVal(false);
        break;
      case "quest":
        dispatch(deleteDataQuest(data));
        setShowVal(false);
        break;
      case "advertisement":
        dispatch(deleteAdvertise(data));
        setShowVal(false);
        break;
      case "blog":
        dispatch(deleteBlogs(data));
        setShowVal(false);
        break;
      case "notification":
        dispatch(deleteNotification(data));
        setShowVal(false);
        break;
        case "Game":
          dispatch(DeleteGameActionV2({id:data}));
          setShowVal(false);
          break;

        
      case "support":
        dispatch(
          CloseSupportupdate({
            id: data,
            status: 1,
          })
        ).then(() => dispatch(GetSupportData()));

        setShowVal(false);
        break;
      case "userAccept":
        dispatch(AdminApprove(data)).then(() => {
          dispatch(Userlist());
        });
        setShowVal(false);
        break;

        case "profile":
          dispatch(DeleteAccount({id:data})).then(() => {
            navigate('/usermanagement')

          });
          setShowVal(false);
          break;
        case "pool":
          dispatch(DeletePool(data)).then((res) => {
            console.log(res)
            // dispatch(getPool())

          });
          setShowVal(false);
          break;


        

      default:
        console.log("No function given");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalHeading[modalName]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBody[modalName]}</Modal.Body>
      <Modal.Footer>
        <Button variant="btn btn-light-dark" onClick={handleClose}>
          No
        </Button>
        <Button variant="btn drk_btn" onClick={handleDel}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
