import React from "react";
import ApexCharts from "apexcharts";
import ReactApexChart from "react-apexcharts";
const PerCountryChart = ({countryGraph,countryData}) => {
 
const series= [{
    name: 'Users',
    data: countryGraph
  }]
   
 const options= {
   
    dataLabels: {
      enabled: false
    },
    // stroke: {
    //   width: 2
    // },
    
    // grid: {
    //   row: {
    //     colors: ['#fff', '#f2f2f2']
    //   }
    // },
    grid: {
        show: false,
      },
    //   legend: {
    //     show: false,
    //   },
    
    xaxis: {
      labels: {
        rotate: -60
      },
      categories: countryData
    //   tickPlacement: 'off'
    },
    yaxis: {
      title: {
        text: 'Users',
      },
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shade: 'light',
    //     type: "horizontal",
    //     shadeIntensity: 0.25,
    //     gradientToColors: undefined,
    //     inverseColors: true,
    //     opacityFrom: 0.85,
    //     opacityTo: 0.85,
    //     stops: [50, 0, 100]
    //   },
    // }
  }


  return (
    <>
      <ReactApexChart options={options}  series={series}  type="bar" height={450} />
    </>
  );
};

export default PerCountryChart;
