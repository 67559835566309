import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { getAccessList } from "../../Redux/Action/LoginAction";
import { useDispatch, useSelector } from "react-redux";
import ConditionalSidebar from "./ConditionalSidebar";

export default function Master(props) {
  const access_list = useSelector((state) => state.Loginreduce.access_list);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAccessList());
  }, []);
  return (
    <div className="layout-boxed">
      <Header />
      <div className="main-container " id="container">
        <div className="overlay"></div>
        <div className="cs-overlay"></div>
        <div className="search-overlay"></div>
        <div className="sidebar-wrapper sidebar-theme">
          {access_list?.role == "admin" && access_list?.accessList && (
            <ConditionalSidebar access_list={access_list?.accessList} />
          )}
          {access_list?.role != "admin" && <Sidebar />}
        </div>

        <div id="content" className="main-content">
          <div className="layout-px-spacing">{props.children}</div>

          <Footer />
        </div>
      </div>
    </div>
  );
}
