import React from 'react'
import { useSelector } from 'react-redux';
import { PacmanLoader } from 'react-spinners';

const Loader = () => {
    const isLoading = useSelector((state) => state.loaderReducer.loading);
  return (
    <>
      {isLoading &&
      (
        <div className="loader-style container-fluid">
          <PacmanLoader
            className="fade-loader"
            loading={isLoading}
            color={"#1b2e4b"}
          />
        </div>
      )}
    </>
  )
}

export default Loader