import React from "react";
import Master from "../Include/Master";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../assets/img/delete.svg";
import editIcon from "../assets/img/edit.svg";
import stopIcon from "../assets/img/stop.svg";
import playIcon from "../assets/img/Play.svg";
import { io } from "socket.io-client";

import {
  addAdvertising,
  deleteAdvertise,
  getAdvertising,
  stopAdvertise,
  updateAdvertisement,
} from "../../Redux/Action/AdminPannel";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";

import Moment2 from "../../Utils/Moment";
import { Link } from "react-router-dom";
import DeleteModal from "../DeleteModal/DeleteModal";
import ReactPaginate from "react-paginate";
import ModalAdver from "./ModalAdver";
import { toast } from "react-toastify";
var today = new Date().toISOString().split("T")[0];
const Advertising = () => {
  const [search, setSearch] = useState("");
  const [validityUpdate, setValidityUpdate] = useState("");
  const [page, setPage] = useState(6);
  const [pageCount, setPageCount] = useState();
  const [resultOffset, setResultOffset] = useState(0);
  const [show, setShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [adverModalShow, setAdverModalShow] = useState(false);
  const [adverVal, setAdverVal] = useState();
  const [delId, setDeleteId] = useState();
  const [update, setUpdate] = useState();
  const [validityOption, setValidityOption] = useState("");
  const [img, setImg] = useState();
  const [incomingData, setIncomingData] = useState({
    banner: "",
    image: "",
  });

  const URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_SOCKET_URL
      : process.env.REACT_APP_SOCKET_URL;

  const socket = io(URL);
  console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
  const [updateAd, setUpdateAd] = useState(false);
  const IMG_URL = process.env.REACT_APP_IMAGE_URL;
  const dispatch = useDispatch();
  const advertiseList = useSelector(
    (state) => state?.AdminReducer?.advertise_list
  );
  console.log(advertiseList, "advertiseList");
  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const changeValidity = (newValidity) => {
    setValidityOption(newValidity);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Enter title";
    }
    // if (!values.img ) {
    //   errors.img = "Choose image";
    // }
    if (!values.live_by_date) {
      errors.live_by_date = "Select date";
    }
    if (!values.description) {
      errors.description = "Write content";
    }

    return errors;
  };
  const resetFields = () => {
    setShow(false);
    setImg();
    setIncomingData({
      banner: "",
      image: "",
    });
    setValidityOption();
  };
  const formik = useFormik({
    initialValues: {
      title: "",
      banner: "",
      description: "",
      live_by_date: "",
      validity: "",
      advertisementimage: "",
      id: "",
    },
    validate,
    onSubmit: async (values) => {
      console.log(validityOption);
      if (!img && !updateAd) {
        return toast.warning("Image or video is required");
      } else if (validityOption === "") {
        return toast.warning("Please select validity");
      } else if ((img || incomingData.image != "") && validityOption !== "") {
        let formData = new FormData();
        img && formData.append("advertisementimage", img);
        !img &&
          incomingData.image != "" &&
          formData.append("advertisementimage", incomingData.image);
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("live_by_date", values.live_by_date);
        formData.append("validity", validityOption);
        formData.append("id", update);
        formData.append(
          "banner",
          img
            ? img?.type.split("/")[0] == "image"
              ? 0
              : 1
            : incomingData.banner
        );

        try {
          resetFields();
          await dispatch(addAdvertising(formData));
          dispatch(getAdvertising());
        } catch (error) {
          console.log(error);
        }
      }
    },
  });

  const deleteAdvertisList = (id) => {
    setDeleteShow(true);
    setDeleteId(id);
  };

  const updateAdvertise = (value) => {
    console.log(value);
    setUpdateAd(true);
    setShow(true);
    setUpdate(value._id);
    setIncomingData(() => {
      return { image: value?.image, banner: value?.banner };
    });
    setValidityOption(value?.validity);
    formik.setFieldValue("title", value?.title);
    formik.setFieldValue("description", value?.description);
    formik.setFieldValue("live_by_date", Moment2.Date(value.live_by_date));
  };
  const stopAdvertisement = (val) => {
    dispatch(stopAdvertise(val)).then((response) => {
      if (response.data.status == 200) {
        socket.emit("force_disconnect");
      }
    });
  };

  const handlePageClick = (e) => {
    setResultOffset((e.selected * page) % advertiseList.length);
  };
  useEffect(() => {
    if (page && advertiseList) {
      setPageCount(Math.ceil(advertiseList.length / page));
    }
  }, [advertiseList, page]);

  useEffect(() => {
    function onConnect() {
      console.log("connected from backend");
    }

    function onDisconnect() {
      console.log("connected from backend");
    }

    function onFooEvent(value) {}

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
    };
  }, []);

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        {/* <!-- BREADCRUMB --> */}
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link>Advertising Management</Link>
              </li>
              {/* <!-- <li className="breadcrumb-item active" aria-current="page">Striped</li> --> */}
            </ol>
          </nav>
        </div>

        {/* <!-- Add Button Modal --> */}
        <DeleteModal
          show={deleteShow}
          data={delId}
          setShowVal={setDeleteShow}
          modalName={"advertisement"}
        />
        <ModalAdver
          adverModalShow={adverModalShow}
          setAdverModalShow={setAdverModalShow}
          adverVal={adverVal}
          stopAdvertisement={stopAdvertisement}
        />
        <div className="row layout-top-spacing mb-2">
          <div className="col-lg-3 col-md-3 col-sm-3 mb-4">
            <div className="position-relative searchbar_blog">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                id="t-text"
                type="text"
                name="txt"
                placeholder="Search"
                className="form-control"
                required=""
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-search"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </div>
          </div>
          <div className="col-xl-9 col-lg-9 col-md-8 col-sm-7 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list d-flex justify-content-end flex-row">
              <div className=" d-flex justify-content-end me-4">
                <button
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  onClick={() => {
                    setShow(true);
                    setUpdate("");
                    setUpdateAd(false);
                    setValidityUpdate("");
                    formik.resetForm();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
              {/* <!-- <div className="btn-group  mb-2 " role="group ml-2">
                                    <button id="btndefault" type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg></button>
                                    <div className="dropdown-menu" aria-labelledby="btndefault">
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-home-fill-1 mr-1"></i>Upcoming</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-gear-fill mr-1"></i>Present</a>
                                        <a href="javascript:void(0);" className="dropdown-item"><i className="flaticon-bell-fill-2 mr-1"></i>Past</a>
                                       
                                    </div>
                                </div> --> */}
            </div>
          </div>
        </div>

        <div className="row">
          {advertiseList
            ?.filter((fil) => fil?.title.toLowerCase().includes(search))
            ?.slice(resultOffset, resultOffset + page)
            .map((item, index) => {
              return (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-4">
                  <a className="card style-2 mb-md-0 mb-4">
                    {item?.banner == 0 ? (
                      <img
                        src={IMG_URL + "advertisement/" + item.image}
                        className="card-img-top"
                        alt="..."
                      />
                    ) : (
                      <video controls className="card-img-top video-height-c">
                        <source
                          src={IMG_URL + "advertisement/" + item.image}
                          type="video/mp4"
                        />
                      </video>
                    )}

                    <div className="card-body px-0 pb-0">
                      <h5 className="card-title mb-3">{item?.title}</h5>
                      <div className="quick-btn-group">
                        <button
                          className="stop"
                          onClick={() => {
                            setAdverVal(item);
                            setAdverModalShow(true);
                          }}
                        >
                          {item?.active == 1 ? (
                            <img src={stopIcon} />
                          ) : (
                            <img src={playIcon} />
                          )}
                        </button>
                        <button
                          className="cancel"
                          onClick={() => updateAdvertise(item)}
                        >
                          <img src={editIcon} />
                        </button>
                        <button
                          className="delete"
                          onClick={() => deleteAdvertisList(item?._id)}
                        >
                          <img src={deleteIcon} />
                        </button>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="paginating-container pagination-solid">
          <ReactPaginate
            activeClassName="active"
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="Prev"
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      </div>

      {/* <!-- Modal --> */}

      <Modal show={show} onHide={!show} className="new_adver">
        <form className="modal-content" onSubmit={formik.handleSubmit}>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {updateAd ? "Update Advertisement" : "Add New Advertisement"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setShow(false);
                formik.resetForm();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div className="modal-body">
            <div className="modal_inner_content">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="contact-name">
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.title}
                      name="title"
                      type="text"
                      id=""
                      className="form-control"
                      placeholder="Title"
                    />
                    <span className="validation-text">
                      {!formik.values.title && formik.errors.title ? (
                        <p style={{ color: "red" }}>{formik.errors.title}*</p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <input
                      onChange={(e) => {
                        if (e.target.files[0].name.split(".").at(-1) == "mov") {
                          toast.warning(
                            "Cannot upload videos with .mov extension"
                          );
                        } else {
                          setImg(e.target.files[0]);
                        }
                      }}
                      name="file"
                      type="file"
                      id=""
                      className="form-control"
                      placeholder="Upload Photo"
                      accept="image/*,video/*"
                    />
                    <span className="validation-text"></span>
                  </div>
                </div>

                <div className="col-md-6 mb-3 d-flex justify-content-between">
                  <div
                    className={
                      (img && img?.type.includes("image")) ||
                      incomingData.banner === 0
                        ? "switch form-switch-custom switch-inline form-switch-primary inner-label-toggle mb-sm-0 mb-3  w-100"
                        : "switch form-switch-custom switch-inline form-switch-primary inner-label-toggle mb-sm-0 mb-3 w-100 show"
                    }
                  >
                    <div className="input-checkbox w-100">
                      <span className="switch-chk-label label-left">
                        Banner
                      </span>
                      <input
                        // onChange={(e) => e.target.value}
                        value="1"
                        className="switch-input"
                        type="checkbox"
                        role="switch"
                        id="form-custom-switch-inner-label"
                        //   onchange="this.checked ? this.closest('.inner-label-toggle').classList.add('show') : this.closest('.inner-label-toggle').classList.remove('show')"
                        checked=""
                        // onClick={() => {
                        //   setCheckBox((prevState) => !prevState);
                        //   setBanner("1");
                        // }}
                      />
                      <span className="switch-chk-label label-right">
                        Full Videos
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3 d-flex justify-content-between">
                  <select
                    id="select-beast"
                    placeholder="Validity"
                    autocomplete="off"
                    onChange={(e) => changeValidity(e.target.value)}
                  >
                    <option value="none" selected="selected" hidden disabled>
                      {updateAd == false
                        ? "Validity"
                        : validityOption == 0
                        ? "After every 10 min"
                        : validityOption == 1
                        ? "After every 1 hour"
                        : validityOption == 2
                        ? "After 1 day"
                        : validityOption == 3
                        ? "After 2 minutes"
                        : validityOption == 4
                        ? "After 3 minutes"
                        : "After 5 minutes"}
                      {/* {updateAd == false
                        ? "Validity"
                        : validityUpdate == 0
                        ? "After every 10 min"
                        : validityUpdate == 1
                        ? "After every 1 hour"
                        : validityUpdate == 2
                        ? "After 1 day"
                        : "Validity"} */}
                    </option>

                    <option value={0}>After every 10 min</option>
                    <option value={1}>After every 1 hour</option>
                    <option value={2}>After 1 day</option>
                    <option value={3}>After 2 minutes</option>
                    <option value={4}>After 3 minutes</option>
                    <option value={5}>After 5 minutes</option>
                  </select>
                </div>

                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <textarea
                      onChange={formik.handleChange}
                      value={formik.values.description}
                      name="description"
                      id=""
                      cols="30"
                      rows="4"
                      className="form-control"
                      placeholder="Description"
                    ></textarea>
                    <span className="validation-text">
                      {!formik.values.description &&
                      formik.errors.description ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.description}*
                        </p>
                      ) : null}
                    </span>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <div className="contact-email">
                    <label>Live by date</label>
                    <input
                      onChange={formik.handleChange}
                      value={formik.values.live_by_date}
                      name="live_by_date"
                      type="date"
                      id=""
                      className="form-control"
                      placeholder="Live by Date"
                      min={today}
                    />
                    <span className="validation-text">
                      {formik.errors.live_by_date ? (
                        <p style={{ color: "red" }}>
                          {formik.errors.live_by_date}*
                        </p>
                      ) : null}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {formik.values.title &&
            formik.values.description &&
            formik.values.live_by_date ? (
              <button
                type="submit"
                className="btn drk_btn"
                // onClick={() => setShow(false)}
              >
                {updateAd ? "Update" : "Add"}
              </button>
            ) : (
              <button type="button" className="btn drk_btn" disabled>
                {updateAd ? "Update" : "Add"}
              </button>
            )}

            <button
              type="button"
              className="btn btn btn-light-dark"
              data-bs-dismiss="modal"
              onClick={() => setShow(false)}
            >
              <i className="flaticon-cancel-12"></i>Cancel
            </button>
          </div>
        </form>
      </Modal>
    </Master>
  );
};

export default Advertising;
