import Api from "../../Server/ConfigApi";
import {
  ADMIN_LIST,
  LOGIN_SUCCESSFULLY,
  SUPPORT_LIST,
  USER_LIST,
  ACCESS_LIST,
} from "../Constant";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "universal-cookie";
import { getNotifications } from "./AdminPannel";

export const LoginAdmin =
  (Loginsubmiteddata, rememberMe) => async (dispatch) => {
    var cookies = new Cookies();
    try {
      const Login = await Api.post("Adminlogin", Loginsubmiteddata);

      if (rememberMe && Login?.data.status == 200) {
        cookies.set("adminToken", Login.data.data.token, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
        sessionStorage.setItem("accessToken", Login.data.data.token);
        window.location.href = "/admin";
      } else if (!rememberMe && Login?.data.status == 200) {
        sessionStorage.setItem("accessToken", Login.data.data.token);
        window.location.href = "/admin";
      } else {
        toast.warning(Login.data.message);
      }
    } catch (error) {
      toast.warning(error.message);
    }
  };

export const Userlist = (details) => async (dispatch) => {
  try {
    let endpoint = "Userlist";
    
    if (details) {
      let queryParams = [];

      if (details?.country.length>0) {
        const countries = details.country.map(country => `country=${country}`).join('&');
        queryParams.push(countries);
      }

      if (details?.gender.length>0) {
        const genders = details.gender.map(gender => `gender=${gender}`).join('&');
        queryParams.push(genders);
      }
      if (details?.gameGenre.length>0) {
        const gameGenres = details.gameGenre.map(gameGenre => `game_geners=${gameGenre}`).join('&');
        queryParams.push(gameGenres);
      }
      if (details?.interests.length>0) {
        const interests = details.interests.map(interests => `interests=${interests}`).join('&');
        queryParams.push(interests);
      }
      if (details?.subscription.length>0) {
        const subscriptions = details.subscription.map(subscription => `is_subscribed=${subscription}`).join('&');
        queryParams.push(subscriptions);
      }
      if (details?.game.length>0) {
        const games = details.game.map(game => `games=${game}`).join('&');
        queryParams.push(games);
      }

      if (queryParams.length > 0) {
        endpoint += `?${queryParams.join('&')}`;
      }
    }
    
    var userlist = await Api.get(endpoint);
    console.log(userlist, "list");
    // localStorage.setItem("filterUrl",endpoint)

    if (userlist?.status === 200) {
      dispatch({
        type: USER_LIST,
        payload: userlist.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const GetSupportData = () => async (dispatch) => {
  try {
    var GetSupport = await Api.get("GetSupport");

    if (GetSupport.data.status == 200) {
      dispatch({
        type: SUPPORT_LIST,
        payload: GetSupport.data.data,
      });
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Userstatusupdate = (updatestatusdata) => async (dispatch) => {
  try {
    var result = await Api.post("Userstatusupdate", updatestatusdata);
    return result;
  } catch (error) {
    toast.warning(error.message);
  }
};

export const deleteNotification = (data) => async (dispatch) => {
  try {
    const result = await Api.delete(`deleteNotification/${data}`);
    if (result.data.status == 200) {
      toast.success(result.data.message);
      dispatch(getNotifications());
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const Writeemail = (Writeemail) => async (dispatch) => {
  try {
    var result = await Api.post("Writeemail", Writeemail);
    console.log(result, "res");

    toast.success(result.data.message);

    return result;
  } catch (error) {
    toast.warning(error.message);
  }
};

export const CloseSupportupdate = (CloseSupportdata) => async (dispatch) => {
  try {
    var result = await Api.post("CloseSupport", CloseSupportdata);
    toast.success(result.data.message);
    return result;
  } catch (error) {
    toast.warning(error.message);
  }
};

export const AdminApprove = (id) => async (dispatch) => {
  try {
    var AdminApprove = await Api.get(`AdminApprove?id=${id}`);

    if (AdminApprove.data.status == 200) {
      toast.success(AdminApprove.data.message);
    }
  } catch (error) {
    toast.warning(error.message);
  }
};

export const changePassword = (data) => async () => {
  const res = await Api.post("/adminChangePassword", data);
  if (res?.data.status == 200) {
    toast.success(res?.data?.message);
    window.location.href = "/dashboard";
  } else {
    toast.error(res?.data?.message);
  }
};

export const getAccessList = () => async (dispatch) => {
  const res = await Api.get("/getAccessList");
  if (res?.data.status == 200) {
    dispatch({
      type: ACCESS_LIST,
      payload: res?.data?.data,
    });
  } else {
    toast.error(res?.data?.message);
  }
};
export const getAdminData = () => async (dispatch) => {
  var res = await Api.get("/getAdmins");
  if (res.data) {
    dispatch({
      type: ADMIN_LIST,
      payload: res.data.data,
    });
  }
};
export const createAmins = (data) => async (dispatch) => {
  const res = await Api.post("/createAdmin", data);
  if (res?.data.status == 200) {
    toast.success(res?.data?.message);
    dispatch(getAdminData());
  } else {
    toast.error(res?.data?.message);
  }
};
export const deleteAdmins = (id) => async (dispatch) => {
  const res = await Api.delete(`/deleteAdmin/${id}`);
  if (res?.data.status == 200) {
    dispatch(getAdminData());
    toast.success(res?.data.message);
  }
  return res;
};
export const editAdmin = (data) => async (dispatch) => {
  const res = await Api.put("/editAdmin", data);
  if (res?.data.status == 200) {
    toast.success(res?.data?.message);
    dispatch(getAdminData());
  } else {
    toast.error(res?.data?.message);
  }
};

export const poolUserList = () => async (dispatch) => {
  const res = await Api.get("/pool-users-list");
  if (res?.data.status == 200) {
    // dispatch({
    //   type: ACCESS_LIST,
    //   payload: res?.data?.data,
    // });
    return res?.data
  } else {
    toast.error(res?.data?.message);
  }
};
