import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSupportData } from "../../Redux/Action/LoginAction";
import { getReportList } from "../../Redux/Action/AdminPannel";

export default function ConditionalSidebar({ access_list }) {
  const [list, setList] = useState();
  const [Listreport, setListreport] = useState();
  const [suportCount, setSuportCount] = useState("");
  const [accessArr, setAccessArr] = useState([]);
  console.log(accessArr,"accessArr");
  const support_list = useSelector((state) => state.Loginreduce.support_list);
  const dispatch = useDispatch();
  const userlist = useSelector((state) => state.Loginreduce.user_list);
  const reportData = useSelector((state) => state.AdminReducer.report_list);
  useEffect(() => {
    dispatch(GetSupportData());
    for (let key in access_list) {
      if (access_list[key] == 1) {
        setAccessArr((prev) => {
          if (!prev?.includes(key)) {
            return [...prev, key];
          }
        });
      }
    }
  }, []);

  useEffect(() => {
  
    const data = userlist.filter(
      (item, i) =>
        item.front_image != null &&
        item?.back_image != null &&
        item.selfie != null &&
        (item?.admin_approve == 0)
    );
    console.log(data, "userlist---userlist")
    setList(data);
  }, [userlist]);

  useEffect(() => {
    dispatch(getReportList());
  }, []);



  useEffect(() => {
    const data = reportData.filter(
      (item, i) =>

        (item?.status_count == 0)

    );
    setListreport(data);
  }, [reportData]);

  useEffect(() => {
    const arr = support_list?.filter((item, i) => item?.user_id != null);
    let data = arr?.map((d) => d?.status);
    function arrayRemove(arr, value) {
      return arr.filter((dd) => {
        return dd != value;
      });
    }
    var result = arrayRemove(data, 1);
    setSuportCount(result.length);
  }, [support_list]);

  const isHref = (pageStr, secondPageStr) => {
    if (
      window.location.href.split("/").includes(pageStr) ||
      window.location.href.split("/").includes(secondPageStr)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const sidebarList = {


    Admin: (
      <li className="menu">
        <Link
          to="/admin"
          aria-expanded={isHref("admin") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-home"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            <span>Admin</span>
          </div>
        </Link>
      </li>
    ),
    Dashboard: (
      <li className="menu">
        <Link
          to="/dashboard"
          aria-expanded={isHref("dashboard") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-home"
            >
              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
              <polyline points="9 22 9 12 15 12 15 22"></polyline>
            </svg>
            <span>Dashboard</span>
          </div>
        </Link>
      </li>
    ),
    Analytics: (
      <li className="menu">
        <Link
          to="/analytics"
          aria-expanded={isHref("analytics") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-sliders"
            >
              <line x1="4" y1="21" x2="4" y2="14"></line>
              <line x1="4" y1="10" x2="4" y2="3"></line>
              <line x1="12" y1="21" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12" y2="3"></line>
              <line x1="20" y1="21" x2="20" y2="16"></line>
              <line x1="20" y1="12" x2="20" y2="3"></line>
              <line x1="1" y1="14" x2="7" y2="14"></line>
              <line x1="9" y1="8" x2="15" y2="8"></line>
              <line x1="17" y1="16" x2="23" y2="16"></line>
            </svg>
            <span>Analytics</span>
          </div>
        </Link>
      </li>
    ),
    WeeklyBox: (
      <li className="menu ">
        <Link
          to="/weekly-box"
          aria-expanded={isHref("weekly-box") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-archive"
            >
              <polyline points="21 8 21 21 3 21 3 8"></polyline>
              <rect x="1" y="3" width="22" height="5"></rect>
              <line x1="10" y1="12" x2="14" y2="12"></line>
            </svg>
            <span>Weekly Box</span>
          </div>
        </Link>
      </li>
    ),
    Quest: (
      <li className="menu">
        <Link
          to="/quest"
          aria-expanded={isHref("quest") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-layers"
            >
              <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
              <polyline points="2 17 12 22 22 17"></polyline>
              <polyline points="2 12 12 17 22 12"></polyline>
            </svg>
            <span>Quest</span>
          </div>
        </Link>
      </li>
    ),
    UserManagement: (
      <li className="menu">
        <Link
          to="/usermanagement"
          aria-expanded={isHref("usermanagement") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-user"
            >
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            <span>User Management</span>
          </div>
        </Link>
      </li>
    ),
    Support: (
      <li className="menu ">
        <Link
          to="/support"
          aria-expanded={isHref("support") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-help-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            <span>Support</span>
            <span className="badge badge-primary sidebar-label">
              {suportCount}
            </span>
          </div>
        </Link>
      </li>
    ),
    UserVerification: (
      <li className="menu ">
        <Link
          to="/userverification"
          aria-expanded={isHref("userverification") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-user-check"
            >
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
              <circle cx="8.5" cy="7" r="4"></circle>
              <polyline points="17 11 19 13 23 9"></polyline>
            </svg>
            <span>User Verification</span>
            <span className="badge badge-primary sidebar-label">{list?.length}</span>
          </div>
        </Link>
      </li>
    ),
    Blogs: (
      <li className="menu">
        <Link
          to="/blogs"
          aria-expanded={isHref("blogs") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-pen-tool"
            >
              <path d="M12 19l7-7 3 3-7 7-3-3z"></path>
              <path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path>
              <path d="M2 2l7.586 7.586"></path>
              <circle cx="11" cy="11" r="2"></circle>
            </svg>
            <span>Blogs</span>
          </div>
        </Link>
      </li>
    ),
    PriceDraws: (
      <li className="menu">
        <Link
          to="/prize-draw"
          aria-expanded={isHref("prize-draw") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-dollar-sign"
            >
              <line x1="12" y1="1" x2="12" y2="23"></line>
              <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
            </svg>
            <span>Price Draws</span>
          </div>
        </Link>
      </li>
    ),
    AdManagement: (
      <li className="menu">
        <Link
          to="/advertising"
          aria-expanded={isHref("advertising") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-monitor"
            >
              <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
              <line x1="8" y1="21" x2="16" y2="21"></line>
              <line x1="12" y1="17" x2="12" y2="21"></line>
            </svg>
            <span>Advertising Management</span>
          </div>
        </Link>
      </li>
    ),
    Notification: (
      <li className="menu ">
        <Link
          to="/notification"
          aria-expanded={isHref("notification") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
            <span>Notification</span>
          </div>
        </Link>
      </li>
    ),
   
    Report: (
      <li className="menu ">
        <Link
          to="/report"
          aria-expanded={isHref("report") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-file-text"
            >
              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
              <polyline points="14 2 14 8 20 8"></polyline>
              <line x1="16" y1="13" x2="8" y2="13"></line>
              <line x1="16" y1="17" x2="8" y2="17"></line>
              <polyline points="10 9 9 9 8 9"></polyline>
            </svg>
            <span>Report</span>
            <span className="badge badge-primary sidebar-label">{Listreport?.length}</span>
          </div>
        </Link>
      </li>
    ),
    Chat: (
      <li className="menu">
        <Link
          to="/chat"
          aria-expanded={isHref("chat") ? "true" : "false"}
          className="dropdown-toggle"
        >
          <div className="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-message-square"
            >
              <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
            </svg>
            <span>Chat</span>
          </div>
        </Link>
      </li>
    ),
  };

  return (
    <nav id="sidebar">
      <div className="navbar-nav theme-brand flex-row  text-center">
        <div className="nav-logo">
          <div className="nav-item theme-logo">
            <a href="index.html">
              <img
                src={require("../assets/img/gp-white-update.svg").default}
                alt="logo"
              />
            </a>
          </div>
        </div>
        <div className="nav-item sidebar-toggle">
          {/* <div className="btn-toggle sidebarCollapse">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </div> */}
          {/* Sidebar components */}
        </div>
      </div>

      <ul className="list-unstyled menu-categories" id="accordionExample">
        {accessArr.map((key) => {

          return sidebarList[key];
        })}
      </ul>
    </nav>
  );
}
