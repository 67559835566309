import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmins, getAdminData } from "../../Redux/Action/LoginAction";
import Master from "../Include/Master";
import AddModal from "./AddModal";
import Dropdown from "react-bootstrap/Dropdown";
export default function Admin() {
  const [showModal, setShowModal] = useState(false);
  const [accessArr, setAccessArr] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateList, setUpdateList] = useState();
  const dispatch = useDispatch();
  const adminDataList = useSelector((state) => state?.Loginreduce?.admin_list);
  const handleSeeRights = (listData) => {
    setAccessArr([]);
    for (let key in listData) {
      if (listData[key] == 1) {
        setAccessArr((prev) => {
          if (!prev?.includes(key)) {
            return [...prev, key];
          }
        });
      }
    }
  };
  useEffect(() => {
    dispatch(getAdminData());
  }, []);
  const handleDeleteAdmins = (id) => {
    dispatch(deleteAdmins(id));
  };
  const handleUpdate = (item) => {
    setShowModal(true);
    setUpdateList(item);
    setUpdate(true);
  };
  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="page-meta">
          <nav className="breadcrumb-style-one" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Admin</a>
              </li>
            </ol>
          </nav>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-md-7 col-sm-5 text-sm-right text-center align-self-center">
            <div className="widget-content searchable-container list">
              <div className=" d-flex justify-content-end">
                <button
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  id="btn-add-contact"
                  variant="primary"
                  onClick={() => {
                    setShowModal(true);
                    setUpdate(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row layout-top-spacing">
          <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
            <div className="widget-content widget-content-area br-8">
            <div className="table-responsive custom_scroll">
            <table
                id="zero-config"
                className="table table-striped dt-table-hover"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Rights</th>
                    <th>Actions</th> 
                  </tr>
                </thead>
                <tbody>
                  {adminDataList?.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td className=" ">
                          <div className="d-flex">
                            <Dropdown
                              className="d-inline "
                              onClick={() => handleSeeRights(item?.access)}
                            >
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                View Rights
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {accessArr?.map((item) => {
                                  return <Dropdown.Item>{item}</Dropdown.Item>;
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <div className="btn-light-dark mx-2 p-1">Support</div>
                        <div className="btn-light-dark mx-2 p-1">Blog</div> */}
                          </div>
                        </td>

                        <td className="edit-del-anchor ">
                          <a onClick={() => handleUpdate(item)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="22"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                          </a>

                          <a
                            onClick={() => handleDeleteAdmins(item._id)}
                            className="delete_ml"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </div>
      </div>
      {showModal == true  && (
        <AddModal
          showModal={showModal}
          setShowModal={setShowModal}
          updateList={updateList}
          update={update}
        />
      )}
    </Master>
  );
}
