export const modalHeading = {
  weeklyBox: "Delete Weekly Box?",
  quest: "Delete Quest?",
  advertisement: "Delete Advertisement?",
  blog: "Delete Blog?",
  support: "Close Support?",
  userAccept: "Verify User?",
  notification:"Delete Notification",
  profile:"Delete profile",
  Game:"Delete Game",
  pool:"Delete Pool"

};

export const modalBody = {
  weeklyBox: "Are you sure you want to Delete this Weekly Box?",
  quest: "Are you sure you want to Delete this Quest?",
  advertisement: "Are you sure you want to Delete this Advertisement?",
  blog: "Are you sure you want to Delete this Blog?",
  support: "Are you sure you want to Close this Support?",
  userAccept:
    "Are you sure you want to accept the verification request for this User?",
  notification:"Are you sure you want to Delete this Notification ?",
  profile:"Are you sure you want to Delete this Profile ?",
  Game:"Are you sure you want to Delete this Game ?",
  pool:"Are you sure you want to Delete this Pool ?",

};
