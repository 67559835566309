import React from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from "../../Redux/Action/LoginAction";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};
    if (!values.old_password) {
      errors.old_password = "Required";
    } else if (!values.new_password) {
      errors.new_password = "Required";
    } else if (values.new_password != values.confirm_password) {
      errors.confirm_password = "Password Doesn't match";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validate,

    onSubmit: (values) => {
      if (values.new_password == values.confirm_password) {
        // console.log(values);
        dispatch(changePassword(values));
      }  
    },
  });
  return (
    <div className="auth-container d-flex">
      <div className="container mx-auto align-self-center">
        <div className="row">
          <div className="col-6 d-lg-flex d-none h-100 my-auto top-0 start-0 text-center justify-content-center flex-column">
            <div className="auth-cover-bg-image"></div>
            <div className="auth-overlay"></div>

            <div className="auth-covers">
              <div className="position-relative">
                <img
                  src={require("../assets/img/login-logo.png")}
                  alt="auth-img"
                  className="img-fluid"
                />

                <h2 className="mt-5 text-white font-weight-bolder px-2">
                  Lorem ipsum dolor sit amet{" "}
                </h2>
                <p className="text-white px-2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                </p>
              </div>
            </div>
          </div>

          <form
            className="col-xxl-4 col-xl-5 col-lg-5 col-md-8 col-12 d-flex flex-column align-self-center ms-lg-auto me-lg-0 mx-auto"
            onSubmit={formik.handleSubmit}
          >
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <h2>Change Password</h2>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="form-label">Old Password</label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.old_password}
                        name="old_password"
                        type="password"
                        className="form-control"
                        id="inputPassword3"
                      />
                    </div>
                    {!formik.values.old_password &&
                    formik.errors.old_password ? (
                      <p style={{ color: "white" }}>
                        {formik.errors.old_password}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label">New Password</label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.new_password}
                        type="password"
                        name="new_password"
                        className="form-control"
                        id="inputPassword3"
                      />
                    </div>
                    {!formik.values.new_password &&
                    formik.errors.new_password ? (
                      <p style={{ color: "white" }}>
                        {formik.errors.new_password}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label">Confirm Password</label>
                      <input
                        onChange={formik.handleChange}
                        value={formik.values.confirm_password}
                        name="confirm_password"
                        type="password"
                        className="form-control"
                        id="inputPassword3"
                      />
                    </div>

                    {formik.errors.confirm_password ? (
                      <p style={{ color: "white" }}>
                        {formik.errors.confirm_password}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <a>
                        <button
                          className="btn btn-secondary w-100"
                          type="submit"
                        >
                          Submit
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
