import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatUserList,
  getUserConvoList,
} from "../../Redux/Action/AdminPannel";
import Master from "../Include/Master";
import ChatBox from "./ChatBox";
import EmptyBox from "./EmptyBox";
import ModalBox from "./ModalBox";
import UserList from "./UserList";
import ViewModal from "./ViewModal";

const Chat = () => {
  const dispatch = useDispatch();
  const [showVal, setShowVal] = useState(false);
  const [viewVal, setViewVal] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [bothUsers, setSelectedUsers] = useState({
    firstUser: "",
    secondUser: "",
  });
  const userList = useSelector((state) => state.AdminReducer.chatUserList);
  const userConvo = useSelector((state) => state.AdminReducer.userConvo);
  const userConvoList = useSelector(
    (state) => state.AdminReducer.userConvoList
  );
  useEffect(() => {
    dispatch(getChatUserList());
  }, []);


  // const handleUserClick = (id) => {
  //   dispatch(getUserConvoList({ userId: id }));
  //   setShowVal(true);
  //   setShowChatBox(false);
  // };

  // const handleViewClick = () => {
  //   setViewVal(true);
  // };

  return (
    <Master>
      <div className="middle-content container-xxl p-0">
        <div className="chat-section layout-top-spacing">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <div className="chat-system">
                <div className="hamburger">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-menu mail-menu d-lg-none"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </div>
                <div className="user-list-box">
                  <div
                    onClick={() => setViewVal(true)}
                    className="view-chat-list mb-3"
                  >
                    View Chat
                  </div>

                  {/* {userList.length > 0 &&
          userList?.map((user) => {
            return (
              <div
                onClick={() => userClicked(user?._id)}
                key={user?._id}
                className="person"
              >
                <div className="user-info d-flex align-items-center">
                  <div className="f-head">
                    <img
                      src={require("../assets/img/profile-4.jpeg")}
                      alt="avatar"
                    />
                  </div>
                  <div className="meta-info">
                    <span className="user-name" data-name="Nia Hillyer">
                      {user?.first_name.concat(" ", user?.sur_name)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })} */}
                </div>
                {/* user list box */}
                {/* {userList && (
                  <UserList
                    viewClicked={handleViewClick}
                    userClicked={handleUserClick}
                    userList={userList}
                  />
                )} */}

                {userList && (
                  <ViewModal
                    setViewVal={setViewVal}
                    viewVal={viewVal}
                    userList={userList}
                    setShowChatBox={setShowChatBox}
                    setBothUsers={setSelectedUsers}
                  />
                )}
                {/* {userConvoList && (
                  <ModalBox
                    setShowVal={setShowVal}
                    showVal={showVal}
                    userConvoList={userConvoList}
                    setShowChatBox={setShowChatBox}
                  />
                )} */}
                {/* Chat box */}
                {userConvo && showChatBox ? (
                  <ChatBox convoUsers={bothUsers} userConvo={userConvo} />
                ) : (
                  <EmptyBox />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Master>
  );
};

export default Chat;
